<template>
  <div id="update-data-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      <span v-if="(this.$route.query.copy != null && this.$route.query.copy === 'true')">複製</span><span v-else>更改</span>iManage 帳戶申請/更新/刪除
    </h2>

    <div v-if="checkPermission(permissions, 'system.workflow.account-workflow')" class="grid grid-cols-12 gap-6 mt-5">
      <div class="col-span-12 lg:col-span-6">
        <account-workflow-form :data="data" :permissions="permissions" :all-sites="allSites" :current-site="currentSite"></account-workflow-form>
      </div>

      <div class="col-span-12 lg:col-span-6" v-if="!(this.$route.query.copy != null && this.$route.query.copy === 'true')">
        <h2 class="text-lg font-medium">
          審批流程
        </h2>

        <!-- <div v-for="(item, key) in data.approvals" :key="key" class="text-xs sm:text-sm flex-col sm:flex-row items-center mt-2 mb-5 box p-5">
          <div class="flex items-center mb-4" v-if="item.user">
            <div class="w-12 h-12 flex-none image-fit">
              <img alt="" class="rounded-full" :src="item.user.avatar != null && item.user.avatar !== '' ? item.avatar : '/images/user.png'">
            </div>
            <div class="ml-3 mr-auto">
              <div class="font-medium">{{ item.user.first_name + " " + item.user.last_name }}</div>
              <div class="text-slate-500">{{ item.user.username }}</div>
            </div>
            <div></div>
          </div>

          <div class="">
            <div class="flex">
              <div class="mr-4 font-bold">
                審批人員
              </div>
              <div class="flex-1">
                {{ item.approver.full_name }}
              </div>
            </div>
            <div class="flex">
              <div class="mr-4 font-bold">
                審批時間
              </div>
              <div class="flex-1">
                {{ item.created_at }}
              </div>
            </div>
            <div class="flex">
              <div class="mr-4 font-bold">
                審批動作
              </div>
              <div class="flex-1">
                {{ item.is_accepted ? '批准' : '' }}
                {{ item.is_rejected ? '拒絕' : '' }}
                {{ item.request_modification ? '要求修改' : '' }}
              </div>
            </div>
            <div class="flex">
              <div class="mr-4 font-bold">
                審批內容
              </div>
              <div class="flex-1">
                {{ item.approval_message ?? '-' }}
              </div>
            </div>
          </div>
        </div> -->

        <div class="text-xs sm:text-sm flex-col sm:flex-row items-center mt-2 mb-5 box p-5">
          <div class="flex" v-for="(item, key) in data.histories" :key="key">
            <div class="mr-4 font-bold">
              {{ item.created_at }}
            </div>
            <div class="flex-1" style="word-break: break-all">
              {{ item.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'allSites', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,
      data: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite() {
      this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadData()
        this.mounted = true
      }
    },
    async loadData() {
      this.data = await this.getWorkflow('account', this.$route.params.applicationId)
    }
  }
}
</script>

<style lang="scss" scoped></style>
