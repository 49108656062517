<template>
  <div id="app" :class="{'home py-5 md:py-0' : auth && !isFile}">
    <site-menu ref="site_menu" v-if="auth && !isFile" :user-sites="userSites" :current-site="currentSite"></site-menu>
    <top-bar v-if="auth && !isFile" @toggle-site-menu="toggleSiteMenu" :current-site="currentSite" :pages="pages" :unread="unread" :notifications="notifications" @load-notification="loadNotifications" :notification-end="notificationEnd" @check-has-unread="loadAllNotifications" :notification-loading="notificationLoading"></top-bar>
    <mobile-menu ref="mobile_menu" @toggle-mobile-menu="toggleMobileMenu" :current-site="currentSite" :permissions="permissions" @pass-pages="getPages"></mobile-menu>

    <div :class="{'flex overflow-hidden': auth && !isFile}">
      <mobile-top-bar v-if="auth && !isFile" :current-site="currentSite" :permissions="permissions" @toggle-mobile-menu="toggleMobileMenu"></mobile-top-bar>
      <side-menu v-if="auth && !isFile" :current-site="currentSite" :permissions="permissions" @pass-pages="getPages"></side-menu>
      <!-- BEGIN: Content -->
      <div :class="{'content': auth && !isFile}">
        <router-view :auth="auth" :all-sites="allSites" :user-sites="userSites" :current-site="currentSite" :permissions="permissions" :profile="profile"></router-view>
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      auth: false,
      isFile: false,
      currentSite: null,
      userSites: [],
      allSites: [],
      permissions: [],
      unread: 0,
      notifications: [],
      notificationPage: 0,
      notificationEnd: false,
      profile: null,
      pages: [],
      notificationLoading: false
    }
  },
  watch: {
    async '$route'(val) {
      // this.$nextTick(() => {
      // })
      if (this.auth) {
        if (this.currentSite) {
          await this.getCurrentPermission()
        } else {
          if (this.currentSite == null || this.currentSite === '') {
            this.toggleSiteMenu()
          }
        }

        await this.loadNotifications()
      }
    }
  },
  mounted() {
    this.$moment().locale('zh-hk')
    this.isLoggedIn()

    if (this.$router.currentRoute.name === 'file') {
      this.isFile = true
    }
  },
  methods: {
    toggleMobileMenu(val) {
      this.$refs.mobile_menu.show = !this.$refs.mobile_menu.show
    },
    async isLoggedIn() {
      // Check accessToken exist
      const accessToken = this.$cookies.get('accessToken')
      if (accessToken == null || accessToken === '') {
        // Check user is logged in or not
        this.auth = false
        // Store before path
        if (this.$route.path != null && this.$route.path !== '' && this.$route.path !== '/' && this.$route.path !== '/forgot-password' && this.$route.path !== '/reset-password') {
          this.$cookies.set('enterUrl', this.$route.path)
          this.$router.push('/')
        }
      } else {
        await this.getProfileAndCurrentSite()
        await this.getUserSites()
        await this.getAllSites()

        if (this.currentSite == null || this.currentSite === '') {
          this.toggleSiteMenu()
        }
      }
    },
    getPages(value) {
      this.pages = value
    },
    async getProfileAndCurrentSite() {
      this.auth = true

      const response = await this.getProfile()
      this.profile = response.profile
      await this.$cookies.set('profile', response.profile)
      await this.$cookies.set('sites', response.sites)

      // const departmentId = response.profile.current_department_id

      this.currentSite = await this.$cookies.get('currentSite')

      if (this.currentSite == null || this.currentSite === '') {
        if (response.sites.length > 0) {
          this.currentSite = response.sites[0]
          await this.$cookies.set('currentSite', response.sites[0])
        }
      }

      // Get Permissions
      await this.getCurrentPermission()

      // Get Notifications
      await this.loadAllNotifications()
      await this.loadNotifications()
    },
    async getUserSites() {
      const response = await this.getProfile()
      this.userSites = response.sites
    },
    async getAllSites() {
      this.allSites = await this.getSites()
    },
    async getCurrentPermission() {
      if (this.currentSite != null) {
        const response = await this.getUserSitePermission(this.currentSite.site_id)
        if (response != null) {
          this.permissions = response.permissions
        }
      }
    },
    async loadAllNotifications() {
      const response = await this.getNotifications(999999, 1)
      let unread = 0
      if (response != null) {
        for (let i = 0; i < response.length; i++) {
          if (!response[i].is_readed) {
            unread++
          }
        }
      }

      this.unread = unread
    },
    async loadNotifications() {
      this.notificationLoading = true
      const response = await this.getNotifications(10, this.notificationPage++)

      if (response.length === 0) {
        this.notificationEnd = true
      } else {
        this.notifications = [...this.notifications, ...response]
      }

      this.notificationLoading = false
    },
    toggleSiteMenu(val) {
      if (!this.$refs.site_menu) {
        return
      }

      this.$refs.site_menu.show = !this.$refs.site_menu.show
    },
    async updateCurrentSiteData(siteId = null) {
      let currentSiteId = null
      if (siteId == null) {
        const currentSite = await this.$cookies.get('currentSite')
        currentSiteId = currentSite.site_id
      } else {
        currentSiteId = siteId
      }

      this.currentSite = await this.getSite(currentSiteId)
      this.$cookies.set('currentSite', this.currentSite)
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
