const network = {
  data() {
    return {
    }
  },
  methods: {
    getHeaders(auth, isFile = false) {
      const headers = {
        'Content-Type': isFile ? 'multipart/form-data' : 'application/json;charset=utf-8'
      }

      if (auth) {
        headers.Authorization = 'Bearer ' + this.$cookies.get('accessToken')
      }

      return headers
    },
    async get(url, auth) {
      const headers = this.getHeaders(auth)

      try {
        const response = await this.$http.get(`${process.env.VUE_APP_SERVER_URL}/${url}`, { headers })
        return response.data
      } catch (error) {
        console.log(error)
        return error
      }
    },
    async post(url, auth, data) {
      const headers = this.getHeaders(auth)

      try {
        const response = await this.$http.post(`${process.env.VUE_APP_SERVER_URL}/${url}`, data, { headers })
        return response.data
      } catch (error) {
        console.log(error)
        return error
      }
    },
    async patch(url, auth, data) {
      const headers = this.getHeaders(auth)

      try {
        const response = await this.$http.patch(`${process.env.VUE_APP_SERVER_URL}/${url}`, data, { headers })
        return response.data
      } catch (error) {
        console.log(error)
        return error
      }
    },
    async put(url, auth, data) {
      const headers = this.getHeaders(auth)

      try {
        const response = await this.$http.put(`${process.env.VUE_APP_SERVER_URL}/${url}`, data, { headers })
        return response.data
      } catch (error) {
        console.log(error)
        return error
      }
    },
    async delete(url, auth) {
      const headers = this.getHeaders(auth)

      try {
        const response = await this.$http.delete(`${process.env.VUE_APP_SERVER_URL}/${url}`, { headers })
        return response.data
      } catch (error) {
        console.log(error)
        return error
      }
    },
    async upload(url, auth, file) {
      const headers = this.getHeaders(auth, true)

      try {
        const data = new FormData()
        data.append('file', file)
        const response = await this.$http.post(`${process.env.VUE_APP_SERVER_URL}/${url}`, data, { headers })
        return response.data
      } catch (error) {
        console.log(error)
        return error
      }
    },
    async download(url, auth) {
      const headers = this.getHeaders(auth)

      try {
        const response = await this.$http.get(`${process.env.VUE_APP_SERVER_URL}/${url}`, { headers, responseType: 'blob' })
        return response
      } catch (error) {
        console.log(error)
        return error
      }
    },

    async getCaptcha() {
      return await this.get('captcha', false)
    },
    async login(username, password) {
      return await this.post('auth/login', false, {
        username, password, captcha: '123'
      })
    },
    async forgetPassword(username) {
      return await this.post('auth/forget-password', false, {
        username
      })
    },
    async resetPassword(username, token, password) {
      return await this.post('auth/password-reset', false, {
        username, token, password
      })
    },
    async sendEmailVerifyCode() {
      return await this.post('auth/email-verify-code', true)
    },
    async verifyEmail(code) {
      return await this.post('auth/verify-email', true, {
        email_verify_code: code
      })
    },

    async getProfile() {
      const response = await this.get('my/profile', true)
      return response.data
    },
    async getUserSitePermission(siteId) {
      const response = await this.get(`my/site/${siteId}/permissions`, true)
      return response.data
    },
    async getNotifications(pageSize = 5, page = 1) {
      const response = await this.get(`my/notifications?page_size=${pageSize}&page=${page}`, true)
      return response.data
    },
    async readNotification(notificationId) {
      const response = await this.get(`my/notifications/${notificationId}`, true)
      return response.data
    },
    async updateNotificationSetting(data) {
      return await this.post('my/notifications/settings', true, data)
    },
    async updatePassword(data) {
      return await this.post('my/update-password', true, data)
    },

    async getSearchResult(siteId, search = '', pageSize = 5, page = 1) {
      const response = await this.get(`site/${siteId}/search?search=${search}&page_size=${pageSize}&page=${page}`, true)
      return response.data
    },

    async getSiteAnnouncementTypes(siteId = 1, status = 'ALL') {
      const response = await this.get(`site/${siteId}/announcement-type?status=${status}`, true)
      return response.data
    },
    async getSiteAnnouncementType(siteId = 1, typeId) {
      const response = await this.get(`site/${siteId}/announcement-type/${typeId}`, true)
      return response.data
    },
    async createSiteAnnouncementType(siteId = 1, data) {
      return await this.post(`site/${siteId}/announcement-type`, true, data)
    },
    async updateSiteAnnouncementType(siteId = 1, typeId, data) {
      return await this.patch(`site/${siteId}/announcement-type/${typeId}`, true, data)
    },
    async deleteSiteAnnouncementType(siteId = 1, typeId) {
      return await this.delete(`site/${siteId}/announcement-type/${typeId}`, true)
    },
    async changeSiteAnnouncementType(siteId, typeId, status) {
      return await this.post(`site/${siteId}/announcement-type/${typeId}/${status}`, true)
    },

    async getSiteAnnouncements(siteId, search = '', typeId = '', pageSize = 5, page = 1, status = 'PUBLISHED', archived = false, orderBy = '', order = '') {
      let statusQuery = ''
      if (status != null && status !== '') {
        statusQuery = `&status=${status}`
      }
      let orderQuery = ''
      if (orderBy != null && orderBy !== '' && order != null && order !== '') {
        orderQuery = `&order_by=${orderBy}&order=${order}`
      }
      return await this.get(`site/${siteId}/announcement?search=${search}&type_id=${typeId}&page_size=${pageSize}&page=${page}&archived=${archived}${orderQuery}${statusQuery}`, true)
    },
    async getSiteAnnouncement(siteId, announcementId) {
      const response = await this.get(`site/${siteId}/announcement/${announcementId}`, true)
      return response.data
    },
    async getSiteLatestAnnouncements(siteId) {
      return await this.get(`site/${siteId}/announcement/latest`, true)
    },
    async createSiteAnnouncement(siteId, data) {
      return await this.post(`site/${siteId}/announcement`, true, data)
    },
    async updateSiteAnnouncement(siteId, announcementId, data) {
      return await this.patch(`site/${siteId}/announcement/${announcementId}`, true, data)
    },
    async deleteSiteAnnouncement(siteId, announcementId) {
      return await this.delete(`site/${siteId}/announcement/${announcementId}`, true)
    },

    async getSiteEssays(siteId, search = '', year = '', pageSize = 5, page = 1, status = 'PUBLISHED', orderBy = '', order = '', isManager = 0) {
      let statusQuery = ''
      if (status != null && status !== '') {
        statusQuery = `&status=${status}`
      }
      let orderQuery = ''
      if (orderBy != null && orderBy !== '' && order != null && order !== '') {
        orderQuery = `&order_by=${orderBy}&order=${order}`
      }
      return await this.get(`site/${siteId}/social-essay?search=${search}&year=${year}&page_size=${pageSize}&page=${page}${orderQuery}${statusQuery}&is_manager=${isManager}`, true)
    },
    async getSiteEssay(siteId, essayId) {
      const response = await this.get(`site/${siteId}/social-essay/${essayId}`, true)
      return response.data
    },
    async createSiteEssay(siteId, data) {
      return await this.post(`site/${siteId}/social-essay`, true, data)
    },
    async updateSiteEssay(siteId, essayId, data) {
      return await this.patch(`site/${siteId}/social-essay/${essayId}`, true, data)
    },
    async deleteSiteEssay(siteId, essayId) {
      return await this.delete(`site/${siteId}/social-essay/${essayId}`, true)
    },
    async likeSiteEssay(siteId, essayId) {
      return await this.post(`site/${siteId}/social-essay/${essayId}/like`, true)
    },
    async unlikeSiteEssay(siteId, essayId) {
      return await this.post(`site/${siteId}/social-essay/${essayId}/unlike`, true)
    },
    async getSiteEssayComments(siteId, essayId, pageSize = 5, page = 1) {
      const response = await this.get(`site/${siteId}/social-essay/${essayId}/comment?page_size=${pageSize}&page=${page}`, true)
      return response.data
    },
    async createSiteEssayComment(siteId, essayId, data) {
      return await this.post(`site/${siteId}/social-essay/${essayId}/comment`, true, data)
    },
    async updateSiteEssayComment(siteId, essayId, commentId, data) {
      return await this.patch(`site/${siteId}/social-essay/${essayId}/comment/${commentId}`, true, data)
    },

    async getCalendars(viewType = 'month', selectedDate = '', siteIds = []) {
      const response = await this.get(`system/calendars/events?view_type=${viewType}&selected_date=${selectedDate}&site_ids=${siteIds.toString()}`, true)
      return response.data
    },

    async getSiteCalendars(siteId) {
      const response = await this.get(`site/${siteId}/calendars`, true)
      return response.data
    },
    async getSiteCalendar(siteId, calendarId) {
      const response = await this.get(`site/${siteId}/calendars/${calendarId}`, true)
      return response.data
    },
    async createSiteCalendar(siteId, data) {
      return await this.post(`site/${siteId}/calendars`, true, data)
    },
    async updateSiteCalendar(siteId, calendarId, data) {
      return await this.patch(`site/${siteId}/calendars/${calendarId}`, true, data)
    },
    async deleteSiteCalendar(siteId, calendarId) {
      return await this.delete(`site/${siteId}/calendars/${calendarId}`, true)
    },

    async getSiteCalendarEvents(siteId, calendarId, viewType = 'month', selectedDate = '') {
      const response = await this.get(`site/${siteId}/calendars/${calendarId}/events?view_type=${viewType}&selected_date=${selectedDate}`, true)
      return response.data
    },
    async getSiteCalendarEvent(siteId, calendarId, eventId) {
      const response = await this.get(`site/${siteId}/calendars/${calendarId}/events/${eventId}`, true)
      return response.data
    },
    async createSiteCalendarEvent(siteId, calendarId, data) {
      return await this.post(`site/${siteId}/calendars/${calendarId}/events`, true, data)
    },
    async updateSiteCalendarEvent(siteId, calendarId, eventId, data) {
      return await this.patch(`site/${siteId}/calendars/${calendarId}/events/${eventId}`, true, data)
    },
    async deleteSiteCalendarEvent(siteId, calendarId, eventId) {
      return await this.delete(`site/${siteId}/calendars/${calendarId}/events/${eventId}`, true)
    },
    async importSiteCalendarEvent(siteId, calendarId, data) {
      return await this.post(`site/${siteId}/calendars/${calendarId}/import`, true, data)
    },

    async getSiteContacts(siteId, search = '', pageSize = 5, page = 1, order = 'ASC', isEffective = true, isHighLevel = false, centerId = '') {
      return await this.get(`site/${siteId}/contacts?search=${search}&page_size=${pageSize}&page=${page}&order=${order}&is_effective=${isEffective}&is_high_level=${isHighLevel}&centre_id=${centerId}`, true)
    },
    async getSiteContact(siteId, contactId) {
      const response = await this.get(`site/${siteId}/contacts/${contactId}`, true)
      return response.data
    },
    async updateSiteContact(siteId, contactId, data) {
      return await this.patch(`site/${siteId}/contacts/${contactId}`, true, data)
    },

    async searchLibraryItems(siteId, search = '', tagSearch = '') {
      const response = await this.get(`site/${siteId}/library/search?search=${search}&tag_search=${tagSearch}`, true)
      return response.data
    },
    async getLibraryItems(siteId, folderCode = '', orderBy = '', order = '') {
      let orderQuery = ''
      if (orderBy !== '' && order !== '') {
        orderQuery = `?order_by=${orderBy}&order=${order}`
      }

      return await this.get(`site/${siteId}/library/folder/${folderCode}${orderQuery}`, true)
    },
    async getLibraryItem(siteId, itemCode) {
      return await this.get(`site/${siteId}/library/item/${itemCode}`, true)
    },
    async deleteLibraryItem(siteId, itemCode) {
      return await this.delete(`site/${siteId}/library/item/${itemCode}`, true)
    },
    async updateLibraryItem(siteId, itemCode, data) {
      return await this.patch(`site/${siteId}/library/item/${itemCode}`, true, data)
    },
    async updateLibraryItemVisibility(siteId, itemCode, data) {
      return await this.patch(`site/${siteId}/library/item/${itemCode}/visible`, true, data)
    },
    async downloadLibraryItem(siteId, itemCode, versionId = '') {
      const response = await this.download(`site/${siteId}/library/item/${itemCode}/download?version_id=${versionId}`, true)
      return response.data
    },
    async createLibraryFolder(siteId, data) {
      return await this.post(`site/${siteId}/library/folder`, true, data)
    },
    async createLibraryItemShareCode(siteId, itemCode) {
      return await this.post(`site/${siteId}/library/item/${itemCode}/share`, true)
    },
    async uploadLibraryItem(siteId, folderId, file) {
      return await this.upload(`site/${siteId}/library/item?folder_id=${folderId}`, true, file)
    },

    async updateFolderPermission(siteId, folderCode, data) {
      return await this.patch(`site/${siteId}/library/folder/${folderCode}/permission`, true, data)
    },
    async getFolderShares(siteId, folderCode) {
      const response = await this.get(`site/${siteId}/library/folder/${folderCode}/shares`, true)
      return response.data
    },
    async createFolderShare(siteId, folderCode, data) {
      return await this.post(`site/${siteId}/library/folder/${folderCode}/shares`, true, data)
    },
    async deleteFolderShare(siteId, folderCode, shareId) {
      return await this.delete(`site/${siteId}/library/folder/${folderCode}/shares/${shareId}`, true)
    },
    async updateFolderShare(siteId, folderCode, shareId, data) {
      return await this.patch(`site/${siteId}/library/folder/${folderCode}/shares/${shareId}`, true, data)
    },
    async getFolderShareGroups(siteId, folderCode) {
      const response = await this.get(`site/${siteId}/library/folder/${folderCode}/shares/group`, true)
      return response.data
    },
    async createFolderShareGroup(siteId, folderCode, data) {
      return await this.post(`site/${siteId}/library/folder/${folderCode}/shares/group`, true, data)
    },
    async deleteFolderShareGroup(siteId, folderCode, shareId) {
      return await this.delete(`site/${siteId}/library/folder/${folderCode}/shares/group/${shareId}`, true)
    },
    async updateFolderShareGroup(siteId, folderCode, shareId, data) {
      return await this.patch(`site/${siteId}/library/folder/${folderCode}/shares/group/${shareId}`, true, data)
    },
    async getItemVersions(siteId, itemCode) {
      const response = await this.get(`site/${siteId}/library/item/${itemCode}/versions`, true)
      return response.data
    },
    async createItemVersion(siteId, itemCode, file) {
      return await this.upload(`site/${siteId}/library/item/${itemCode}/version`, true, file)
    },
    async addLibraryItemTag(siteId, itemCode, data) {
      return await this.post(`site/${siteId}/library/item/${itemCode}/tag`, true, data)
    },
    async removeLibraryItemTag(siteId, itemCode, tagId) {
      return await this.delete(`site/${siteId}/library/item/${itemCode}/tag/${tagId}`, true)
    },

    async getItemByShareCode(shareCode) {
      return await this.get(`library/share/${shareCode}`, true)
    },
    async downloadItemByShareCode(shareCode) {
      const response = await this.download(`library/share/${shareCode}/download`, true)
      return response.data
    },

    async getCenterCategory(centerCategoryId) {
      const response = await this.get(`system/centre-category/${centerCategoryId}`, true)
      return response.data
    },
    async getCenterCategories() {
      const response = await this.get('system/centre-category', true)
      return response.data
    },
    async createCenterCategory(data) {
      return await this.post('system/centre-category', true, data)
    },
    async updateCenterCategory(centerCategoryId, data) {
      return await this.patch(`system/centre-category/${centerCategoryId}`, true, data)
    },
    async deleteCenterCategory(centerCategoryId) {
      return await this.delete(`system/centre-category/${centerCategoryId}`, true)
    },

    async getCenter(centerId) {
      const response = await this.get(`system/centre/${centerId}`, true)
      return response.data
    },
    async getCenters(search = '') {
      const response = await this.get(`system/centre?search=${search}`, true)
      return response.data
    },
    async createCenter(data) {
      return await this.post('system/centre', true, data)
    },
    async updateCenter(centerId, data) {
      return await this.patch(`system/centre/${centerId}`, true, data)
    },
    async deleteCenter(centerId) {
      return await this.delete(`system/centre/${centerId}`, true)
    },

    async getPosition(positionId) {
      const response = await this.get(`system/position/${positionId}`, true)
      return response.data
    },
    async getPositions() {
      const response = await this.get('system/position', true)
      return response.data
    },
    async createPosition(data) {
      return await this.post('system/position', true, data)
    },
    async updatePosition(positionId, data) {
      return await this.patch(`system/position/${positionId}`, true, data)
    },
    async deletePosition(positionId) {
      return await this.delete(`system/position/${positionId}`, true)
    },

    async getWorkflows(workflowType, pageSize = 5, page = 1, applicationStatus = 'SUBMITTED', applicationType = '', isEffective = true, isHighLevel = false, centreId = '', orderBy = '', order = '', isMine = true) {
      return await this.get(`system/workflows/${workflowType}-workflows?page_size=${pageSize}&page=${page}&application_status=${applicationStatus}&application_type=${applicationType}&is_effective=${isEffective}&is_high_level=${isHighLevel}&centre_id=${centreId}&order_by=${orderBy}&order=${order}&is_mine=${isMine}`, true)
    },
    async getWorkflow(workflowType, applicationId) {
      const response = await this.get(`system/workflows/${workflowType}-workflows/${applicationId}`, true)
      return response.data
    },
    async createWorkflow(workflowType, isDraft, data) {
      if (isDraft) {
        return await this.post(`system/workflows/${workflowType}-workflows?isDraft=${isDraft}`, true, data)
      } else {
        return await this.post(`system/workflows/${workflowType}-workflows`, true, data)
      }
    },
    async updateWorkflow(workflowType, applicationId, data) {
      return await this.patch(`system/workflows/${workflowType}-workflows/${applicationId}`, true, data)
    },
    async deleteWorkflow(workflowType, applicationId) {
      return await this.delete(`system/workflows/${workflowType}-workflows/${applicationId}`, true)
    },
    async confirmWorkflow(workflowType, applicationId) {
      return await this.post(`system/workflows/${workflowType}-workflows/${applicationId}/confirm`, true)
    },
    async approveWorkflow(workflowType, applicationId, data) {
      return await this.post(`system/workflows/${workflowType}-workflows/${applicationId}/approve`, true, data)
    },
    async rejectWorkflow(workflowType, applicationId, data) {
      return await this.post(`system/workflows/${workflowType}-workflows/${applicationId}/reject`, true, data)
    },
    async requestModifyWorkflow(workflowType, applicationId, data) {
      return await this.post(`system/workflows/${workflowType}-workflows/${applicationId}/request-modify`, true, data)
    },
    async highLevelApproveWorkflow(workflowType, applicationId, data) {
      return await this.post(`system/workflows/${workflowType}-workflows/${applicationId}/high-level-approve`, true, data)
    },
    async highLevelRejectWorkflow(workflowType, applicationId, data) {
      return await this.post(`system/workflows/${workflowType}-workflows/${applicationId}/high-level-reject`, true, data)
    },
    async highLevelRequestModifyWorkflow(workflowType, applicationId, data) {
      return await this.post(`system/workflows/${workflowType}-workflows/${applicationId}/high-level-request-modify`, true, data)
    },
    async downloadWorkflow(workflowType, applicationId) {
      const response = await this.download(`system/workflows/${workflowType}-workflows/${applicationId}/export`, true)
      return response.data
    },
    async getWorkflowSignature() {
      const response = await this.get('system/workflows/signatures', true)
      return response.data
    },

    async getSiteResources(siteId, type = '', search = '', pageSize = 5, page = 1, isAvailable = '') {
      return await this.get(`site/${siteId}/resources?resource_type=${type}&page_size=${pageSize}&page=${page}&search=${search}&is_available=${isAvailable}`, true)
    },
    async getSiteAvailableResources(siteId, type = '', search = '', pageSize = 5, page = 1) {
      return await this.get(`site/${siteId}/resources/available-list?resource_type=${type}&page_size=${pageSize}&page=${page}&search=${search}`, true)
    },
    async getSiteResource(siteId, resourceId) {
      const response = await this.get(`site/${siteId}/resources/${resourceId}`, true)
      return response.data
    },
    async createSiteResource(siteId, data) {
      return await this.post(`site/${siteId}/resources`, true, data)
    },
    async updateSiteResource(siteId, resourceId, data) {
      return await this.patch(`site/${siteId}/resources/${resourceId}`, true, data)
    },
    async deleteSiteResource(siteId, resourceId) {
      return await this.delete(`site/${siteId}/resources/${resourceId}`, true)
    },

    async getSiteForms(siteId, search = '', pageSize = 5, page = 1, orderBy = '', order = '', status = '') {
      let orderQuery = ''
      if (orderBy !== '' && order !== '') {
        orderQuery = `&order_by=${orderBy}&order=${order}`
      }
      return await this.get(`site/${siteId}/forms?page_size=${pageSize}&page=${page}&search=${search}${orderQuery}&status=${status}`, true)
    },
    async getSiteForm(siteId, formId) {
      const response = await this.get(`site/${siteId}/forms/${formId}`, true)
      return response.data
    },
    async createSiteForm(siteId, data) {
      return await this.post(`site/${siteId}/forms`, true, data)
    },
    async updateSiteForm(siteId, formId, data) {
      return await this.patch(`site/${siteId}/forms/${formId}`, true, data)
    },
    async deleteSiteForm(siteId, formId) {
      return await this.delete(`site/${siteId}/forms/${formId}`, true)
    },
    async copySiteForm(siteId, formId) {
      return await this.post(`site/${siteId}/forms/${formId}/copy`, true)
    },

    async getSiteFormPermissions(siteId, formId) {
      const response = await this.get(`site/${siteId}/forms/${formId}/permissions`, true)
      return response.data
    },
    async toggleSiteFormPermissions(siteId, formId, data) {
      return await this.post(`site/${siteId}/forms/${formId}/permissions`, true, data)
    },
    async toggleSiteFormPermissionsGroup(siteId, formId, data) {
      return await this.post(`site/${siteId}/forms/${formId}/permissions/group`, true, data)
    },

    async downloadFormResult(siteId, formId) {
      const response = await this.download(`site/${siteId}/forms/${formId}/export`, true)
      return response.data
    },

    async getSiteFormItems(siteId, formId) {
      const response = await this.get(`site/${siteId}/forms/${formId}/item`, true)
      return response.data
    },
    async getSiteFormItem(siteId, formId, itemId) {
      const response = await this.get(`site/${siteId}/forms/${formId}/item/${itemId}`, true)
      return response.data
    },
    async createSiteFormItem(siteId, formId, data) {
      return await this.post(`site/${siteId}/forms/${formId}/item`, true, data)
    },
    async updateSiteFormItem(siteId, formId, itemId, data) {
      return await this.patch(`site/${siteId}/forms/${formId}/item/${itemId}`, true, data)
    },
    async deleteSiteFormItem(siteId, formId, itemId) {
      return await this.delete(`site/${siteId}/forms/${formId}/item/${itemId}`, true)
    },

    async submitForm(siteId, formId, data) {
      return await this.post(`site/${siteId}/forms/${formId}/submit`, true, data)
    },
    async updateResponse(siteId, formId, resourceId, data) {
      return await this.post(`site/${siteId}/forms/${formId}/response/${resourceId}/update`, true, data)
    },
    async getAllSubmittedUsers(siteId, formId) {
      const response = await this.get(`site/${siteId}/forms/${formId}/all-submitted-users`, true)
      return response.data
    },
    async getUserResponse(siteId, formId, userId) {
      const response = await this.get(`site/${siteId}/forms/${formId}/user-responses?user_id=${userId}`, true)
      return response.data
    },
    async getResponse(siteId, formId, resourceId) {
      const response = await this.get(`site/${siteId}/forms/${formId}/response/${resourceId}`, true)
      return response.data
    },
    async getMyResponse(siteId, formId) {
      const response = await this.get(`site/${siteId}/forms/${formId}/my-responses`, true)
      return response.data
    },
    async clearFormResponse(siteId, formId) {
      return await this.post(`site/${siteId}/forms/${formId}/clear`, true)
    },

    async getSiteFormSections(siteId, formId) {
      const response = await this.get(`site/${siteId}/forms/${formId}/section`, true)
      return response.data
    },
    async getSiteFormSection(siteId, formId, sectionId) {
      const response = await this.get(`site/${siteId}/forms/${formId}/section/${sectionId}`, true)
      return response.data
    },
    async createSiteFormSection(siteId, formId, data) {
      return await this.post(`site/${siteId}/forms/${formId}/section`, true, data)
    },
    async updateSiteFormSection(siteId, formId, sectionId, data) {
      return await this.patch(`site/${siteId}/forms/${formId}/section/${sectionId}`, true, data)
    },
    async deleteSiteFormSection(siteId, formId, sectionId) {
      return await this.delete(`site/${siteId}/forms/${formId}/section/${sectionId}`, true)
    },

    async getWeather() {
      const response = await this.$http.get('https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=rhrread&lang=tc')
      return response.data
    },

    async getUsers() {
      const response = await this.get('system/user', true)
      return response.data
    },
    async getUser(userId) {
      const response = await this.get(`system/user/${userId}`, true)
      return response.data
    },
    async getUserStaffNumber(staffNum) {
      return await this.get(`system/user/staff-number/${staffNum}`, true)
    },
    async createUser(data) {
      return await this.post('system/user', true, data)
    },
    async updateUser(userId, data) {
      return await this.patch(`system/user/${userId}`, true, data)
    },
    async deleteUser(userId) {
      return await this.delete(`system/user/${userId}`, true)
    },

    async getUserPermissions(userId) {
      const response = await this.get(`system/user/${userId}/site-permissions`, true)
      return response.data
    },

    async getSites(type = '') {
      const response = await this.get(`system/site?type=${type}`, true)
      return response.data
    },
    async getSite(siteId) {
      const response = await this.get(`system/site/${siteId}`, true)
      return response.data
    },
    async createSite(data) {
      return await this.post('system/site', true, data)
    },
    async updateSite(siteId, data) {
      return await this.patch(`system/site/${siteId}`, true, data)
    },
    async deleteSite(siteId) {
      return await this.delete(`system/site/${siteId}`, true)
    },

    async getUserGroups(siteId) {
      const response = await this.get(`site/${siteId}/user-group`, true)
      return response.data
    },
    async getUserGroup(siteId, groupId) {
      return await this.get(`site/${siteId}/user-group/${groupId}`, true)
    },
    async createUserGroup(siteId, data) {
      return await this.post(`site/${siteId}/user-group`, true, data)
    },
    async updateUserGroup(siteId, groupId, data) {
      return await this.patch(`site/${siteId}/user-group/${groupId}`, true, data)
    },
    async deleteUserGroup(siteId, groupId) {
      return await this.delete(`site/${siteId}/user-group/${groupId}`, true)
    },
    async addUserGroupUsers(siteId, groupId, data) {
      return await this.post(`site/${siteId}/user-group/${groupId}/enroll`, true, data)
    },
    async removeUserGroupUsers(siteId, groupId, data) {
      return await this.post(`site/${siteId}/user-group/${groupId}/unenroll`, true, data)
    },

    async getSystemConfig(configType) {
      const response = await this.get(`system/configs/${configType}`, true)
      return response.data
    },
    async saveSystemConfig(configType, data) {
      return await this.patch(`system/configs/${configType}`, true, data)
    },

    async getRoles(siteId = '') {
      const response = await this.get(`system/role?site_id=${siteId}`, true)
      return response.data
    },
    async getRole(roleId) {
      const response = await this.get(`system/role/${roleId}`, true)
      return response.data
    },
    async createRole(data) {
      return await this.post('system/role', true, data)
    },
    async updateRole(roleId, data) {
      return await this.patch(`system/role/${roleId}`, true, data)
    },
    async deleteRole(roleId) {
      return await this.delete(`system/role/${roleId}`, true)
    },
    async updateRolePermission(roleId, data) {
      return await this.patch(`system/role/${roleId}/permission`, true, data)
    },
    async getPermissions() {
      const response = await this.get('system/permission', true)
      return response.data
    },

    async getSiteUsers(siteId, search = '', pageSize = 5, page = 1, orderBy = '', order = '') {
      let orderQuery = ''
      if (orderBy !== '' && order !== '') {
        orderQuery = `&order_by=${orderBy}&order=${order}`
      }
      return await this.get(`system/site/${siteId}/users?search=${search}&page_size=${pageSize}&page=${page}${orderQuery}`, true)
    },
    async addSiteGroup(siteId, data) {
      return await this.post(`system/site/${siteId}/group-enroll`, true, data)
    },
    async addSiteUsers(siteId, data) {
      return await this.post(`system/site/${siteId}/enroll`, true, data)
    },
    async removeSiteUsers(siteId, data) {
      return await this.post(`system/site/${siteId}/unenroll`, true, data)
    },
    async toggleSiteUser(siteId, userId) {
      return await this.post(`system/site/${siteId}/users/${userId}/toggle`, true, null)
    },

    async getAnnouncementTypes(status = 'EFFECTIVE') {
      const response = await this.get(`system/announcement-type?status=${status}`, true)
      return response.data
    },
    async getAnnouncementType(typeId) {
      const response = await this.get(`system/announcement-type/${typeId}`, true)
      return response.data
    },
    async createAnnouncementType(data) {
      return await this.post('system/announcement-type', true, data)
    },
    async updateAnnouncementType(typeId, data) {
      return await this.patch(`system/announcement-type/${typeId}`, true, data)
    },
    async deleteAnnouncementType(typeId) {
      return await this.delete(`system/announcement-type/${typeId}`, true)
    },

    async getSiteBanners(siteId) {
      const response = await this.get(`site/${siteId}/banner`, true)
      return response.data
    },
    async getSiteBanner(siteId, bannerId) {
      const response = await this.get(`site/${siteId}/banner/${bannerId}`, true)
      return response.data
    },
    async createSiteBanner(siteId, data) {
      return await this.post(`site/${siteId}/banner`, true, data)
    },
    async updateSiteBanner(siteId, bannerId, data) {
      return await this.patch(`site/${siteId}/banner/${bannerId}`, true, data)
    },
    async deleteSiteBanner(siteId, bannerId) {
      return await this.delete(`site/${siteId}/banner/${bannerId}`, true)
    },

    async getLibraryTags() {
      const response = await this.get('system/library-tag', true)
      return response.data
    },
    async createLibraryTag(data) {
      return await this.post('system/library-tag', true, data)
    },
    async updateLibraryTag(tagId, data) {
      return await this.patch(`system/library-tag/${tagId}`, true, data)
    },
    async deleteLibraryTag(tagId) {
      return await this.delete(`system/library-tag/${tagId}`, true)
    },

    async getSiteUserResourceBookings(siteId, pageSize = 5, page = 1) {
      return await this.get(`site/${siteId}/resource-bookings/my-bookings?page_size=${pageSize}&page=${page}`, true)
    },
    async getSiteResourceBookings(siteId, pageSize = 5, page = 1, orderBy = '', order = '') {
      let orderQuery = ''
      if (orderBy !== '' && order !== '') {
        orderQuery = `&order_by=${orderBy}&order=${order}`
      }
      return await this.get(`site/${siteId}/resource-bookings?page_size=${pageSize}&page=${page}${orderQuery}`, true)
    },
    async getSiteResourceBooking(siteId, bookingId) {
      const response = await this.get(`site/${siteId}/resource-bookings/${bookingId}`, true)
      return response.data
    },
    async createSiteResourceBooking(siteId, data) {
      return await this.post(`site/${siteId}/resource-bookings`, true, data)
    },
    async updateSiteResourceBooking(siteId, bookingId, data) {
      return await this.patch(`site/${siteId}/resource-bookings/${bookingId}`, true, data)
    },
    async acceptSiteResourceBooking(siteId, bookingId) {
      return await this.post(`site/${siteId}/resource-bookings/${bookingId}/accept`, true)
    },
    async rejectSiteResourceBooking(siteId, bookingId) {
      return await this.post(`site/${siteId}/resource-bookings/${bookingId}/reject`, true)
    },
    async forceReturnSiteResourceBooking(siteId, bookingId) {
      return await this.post(`site/${siteId}/resource-bookings/${bookingId}/force-return`, true)
    },
    async downloadResourceBookingRoster(siteId, date) {
      const response = await this.download(`site/${siteId}/resource-bookings/export-roster?date=${date}`, true)
      return response.data
    },

    async getSiteFaqTypes(siteId, status = 'PUBLISHED') {
      const response = await this.get(`site/${siteId}/knowledge-types?status=${status}`, true)
      return response.data
    },
    async getSiteFaqType(siteId, typeId) {
      const response = await this.get(`site/${siteId}/knowledge-types/${typeId}`, true)
      return response.data
    },
    async createSiteFaqType(siteId, data) {
      return await this.post(`site/${siteId}/knowledge-types`, true, data)
    },
    async updateSiteFaqType(siteId, typeId, data) {
      return await this.patch(`site/${siteId}/knowledge-types/${typeId}`, true, data)
    },
    async deleteSiteFaqType(siteId, typeId) {
      return await this.delete(`site/${siteId}/knowledge-types/${typeId}`, true)
    },

    async getSiteFaqBases(siteId, search = '', typeId = '', pageSize = 5, page = 1, order = 'ASC', status = 'PUBLISHED') {
      const response = await this.get(`site/${siteId}/knowledge-bases?search=${search}&type_id=${typeId}&page_size=${pageSize}&page=${page}&order=${order}&status=${status}`, true)
      return response
    },
    async getSiteFaqBase(siteId, knowledgeId) {
      const response = await this.get(`site/${siteId}/knowledge-bases/${knowledgeId}`, true)
      return response.data
    },
    async createSiteFaqBase(siteId, data) {
      return await this.post(`site/${siteId}/knowledge-bases`, true, data)
    },
    async updateSiteFaqBase(siteId, knowledgeId, data) {
      return await this.patch(`site/${siteId}/knowledge-bases/${knowledgeId}`, true, data)
    },
    async deleteSiteFaqBase(siteId, knowledgeId) {
      return await this.delete(`site/${siteId}/knowledge-bases/${knowledgeId}`, true)
    },
    async downloadSiteFaqBase(siteId, typeId = '') {
      return await this.download(`site/${siteId}/knowledge-bases/export?type_id=${typeId}`, true)
    },

    async getSiteForumCategories(siteId) {
      const response = await this.get(`site/${siteId}/forum-categories`, true)
      return response.data
    },
    async getSiteForumCategory(siteId, categoryId) {
      const response = await this.get(`site/${siteId}/forum-categories/${categoryId}`, true)
      return response.data
    },
    async createSiteForumCategory(siteId, data) {
      return await this.post(`site/${siteId}/forum-categories`, true, data)
    },
    async updateSiteForumCategory(siteId, categoryId, data) {
      return await this.patch(`site/${siteId}/forum-categories/${categoryId}`, true, data)
    },
    async deleteSiteForumCategory(siteId, categoryId) {
      return await this.delete(`site/${siteId}/forum-categories/${categoryId}`, true)
    },

    async getSiteForumThreads(siteId, search = '', categoryId = '', pageSize = 5, page = 1, status = 'PUBLISHED') {
      let statusQuery = ''
      if (status != null && status !== '') {
        statusQuery = `&status=${status}`
      }

      return await this.get(`site/${siteId}/forum-threads?search=${search}&category_id=${categoryId}&page_size=${pageSize}&page=${page}${statusQuery}`, true)
    },
    async getSiteForumThread(siteId, threadId) {
      const response = await this.get(`site/${siteId}/forum-threads/${threadId}`, true)
      return response.data
    },
    async createSiteForumThread(siteId, data) {
      return await this.post(`site/${siteId}/forum-threads`, true, data)
    },
    async updateSiteForumThread(siteId, threadId, data) {
      return await this.patch(`site/${siteId}/forum-threads/${threadId}`, true, data)
    },
    async deleteSiteForumThread(siteId, threadId) {
      return await this.delete(`site/${siteId}/forum-threads/${threadId}`, true)
    },

    async getSiteForumPosts(siteId, threadId, pageSize = 5, page = 1) {
      return await this.get(`site/${siteId}/forum-threads/${threadId}/posts?page_size=${pageSize}&page=${page}`, true)
    },
    async getSiteForumPost(siteId, threadId) {
      const response = await this.get(`site/${siteId}/forum-posts/${threadId}`, true)
      return response.data
    },
    async createSiteForumPost(siteId, data) {
      return await this.post(`site/${siteId}/forum-posts`, true, data)
    },
    async updateSiteForumPost(siteId, postId, data) {
      return await this.patch(`site/${siteId}/forum-posts/${postId}`, true, data)
    },
    async deleteSiteForumPost(siteId, postId) {
      return await this.delete(`site/${siteId}/forum-posts/${postId}`, true)
    },

    async getStatHots(siteId) {
      const response = await this.get(`site/${siteId}/stat/hots`, true)
      return response.data
    },

    async getPerformanceReportSections() {
      return await this.get('system/performance-report/section', true)
    },
    async getPerformanceReportSection(sectionId, versionId = '') {
      const response = await this.get(`system/performance-report/section/${sectionId}?version_id=${versionId}`, true)
      return response.data
    },
    async createPerformanceReportSection(data) {
      return await this.post('system/performance-report/section', true, data)
    },
    async updatePerformanceReportSection(sectionId, data) {
      return await this.patch(`system/performance-report/section/${sectionId}`, true, data)
    },
    async deletePerformanceReportSection(sectionId) {
      return await this.delete(`system/performance-report/section/${sectionId}`, true)
    },
    async getPerformanceReportSectionTables(sectionId, versionId = '') {
      return await this.get(`system/performance-report/section/${sectionId}/table?version_id=${versionId}`, true)
    },
    async getPerformanceReportSectionTable(sectionId, tableId, versionId = '') {
      const response = await this.get(`system/performance-report/section/${sectionId}/table/${tableId}?version_id=${versionId}`, true)
      return response.data
    },
    async createPerformanceReportSectionTable(sectionId, data) {
      return await this.post(`system/performance-report/section/${sectionId}/table`, true, data)
    },
    async updatePerformanceReportSectionTable(sectionId, tableId, data) {
      return await this.patch(`system/performance-report/section/${sectionId}/table/${tableId}`, true, data)
    },
    async deletePerformanceReportSectionTable(sectionId, tableId) {
      return await this.delete(`system/performance-report/section/${sectionId}/table/${tableId}`, true)
    },
    async getPerformanceReportSectionTableRows(sectionId, tableId) {
      return await this.get(`system/performance-report/section/${sectionId}/table/${tableId}/row`, true)
    },
    async getPerformanceReportSectionTableRow(sectionId, tableId, rowId) {
      const response = await this.get(`system/performance-report/section/${sectionId}/table/${tableId}/row/${rowId}`, true)
      return response.data
    },
    async createPerformanceReportSectionTableRow(sectionId, tableId, data) {
      return await this.post(`system/performance-report/section/${sectionId}/table/${tableId}/row`, true, data)
    },
    async updatePerformanceReportSectionTableRow(sectionId, tableId, rowId, data) {
      return await this.patch(`system/performance-report/section/${sectionId}/table/${tableId}/row/${rowId}`, true, data)
    },
    async deletePerformanceReportSectionTableRow(sectionId, tableId, rowId) {
      return await this.delete(`system/performance-report/section/${sectionId}/table/${tableId}/row/${rowId}`, true)
    },
    async getPerformanceReportSectionTableRowFields(sectionId, tableId, rowId) {
      return await this.get(`system/performance-report/section/${sectionId}/table/${tableId}/row/${rowId}`, true)
    },
    async getPerformanceReportSectionTableRowField(sectionId, tableId, rowId, fieldId) {
      const response = await this.get(`system/performance-report/section/${sectionId}/table/${tableId}/row/${rowId}/field/${fieldId}`, true)
      return response.data
    },
    async createPerformanceReportSectionTableRowField(sectionId, tableId, rowId, data) {
      return await this.post(`system/performance-report/section/${sectionId}/table/${tableId}/row/${rowId}/field`, true, data)
    },
    async updatePerformanceReportSectionTableRowField(sectionId, tableId, rowId, fieldId, data) {
      return await this.patch(`system/performance-report/section/${sectionId}/table/${tableId}/row/${rowId}/field/${fieldId}`, true, data)
    },
    async deletePerformanceReportSectionTableRowField(sectionId, tableId, rowId, fieldId) {
      return await this.delete(`system/performance-report/section/${sectionId}/table/${tableId}/row/${rowId}/field/${fieldId}`, true)
    },
    async getPerformanceReportVersions() {
      return await this.get('system/performance-report/version', true)
    },
    async getPerformanceReportVersion(versionId) {
      return await this.get(`system/performance-report/version/${versionId}`, true)
    },
    async createPerformanceReportVersion(data) {
      return await this.post('system/performance-report/version/', true, data)
    },
    async updatePerformanceReportVersion(versionId, data) {
      return await this.patch(`system/performance-report/version/${versionId}`, true, data)
    },
    async deletePerformanceReportVersion(versionId) {
      return await this.delete(`system/performance-report/version/${versionId}`, true)
    },
    async getPerformanceReportPermissionRows() {
      const response = await this.get('system/performance-report/permission-row', true)
      return response.data
    },
    async submitPerformanceReportData(versionId, data) {
      return await this.post(`system/performance-report/version/${versionId}/submit-data`, true, data)
    },
    async exportPerformanceReport(versionId) {
      const response = await this.download(`system/performance-report/export?version=${versionId}`, true)
      return response.data
    },

    async downloadMailingList(type, columns = []) {
      const cols = columns.join(',')
      const response = await this.download(`system/exports/mailing-list?type=${type}&cols=${cols}`, true)
      return response.data
    },

    async getLineOfSupervisions() {
      const response = await this.get('system/line-of-supervision', true)
      return response.data
    },
    async getLineOfSupervision(id) {
      const response = await this.get(`system/line-of-supervision/${id}`, true)
      return response.data
    },
    async createLineOfSupervision(data) {
      return await this.post('system/line-of-supervision', true, data)
    },
    async updateLineOfSupervision(id, data) {
      return await this.patch(`system/line-of-supervision/${id}`, true, data)
    },
    async deleteLineOfSupervision(id) {
      return await this.delete(`system/line-of-supervision/${id}`, true)
    }
  }
}

export default network
