<template>
  <div id="library-page" :class="{'page': !modal}">
    <div class="mt-10">
      <h2 v-if="search != null && search != ''" class="intro-y text-lg font-medium">
        「{{ search }}」的搜尋結果
      </h2>

      <div v-else-if="modal == null || !modal" class="flex align-items-center overflow-x-auto directory-list">
        <h2 class="intro-y text-lg font-medium root">

          <router-link to="/library" v-if="$route.path !== '/library'">
            檔案櫃
          </router-link>
          <span v-else>檔案櫃</span>
        </h2>

        <span v-for="(item, key) in parentFolders" :key="key" class="flex align-items-center">
          <font-awesome-icon icon="fa-solid fa-chevron-right" class="ml-4 mr-4"/>

          <router-link :to="`/library/${item.item_code}`"
                       class="intro-y text-lg font-medium">
            {{ item.item_name }}
          </router-link>
        </span>

        <font-awesome-icon v-if="item != null" icon="fa-solid fa-chevron-right" class="ml-4 mr-4"/>

        <div class="intro-y text-lg font-medium" v-if="item != null">{{ item.item_name }}</div>
      </div>

      <div v-else class="flex align-items-center overflow-x-auto directory-list px-4">
        <h2 class="intro-y text-lg font-medium root">

          <button @click="onChangeFolder()" v-if="$route.path !== '/library'">
            檔案櫃
          </button>
          <span v-else>檔案櫃</span>
        </h2>

        <span v-for="(item, key) in parentFolders" :key="key" class="flex align-items-center">
          <font-awesome-icon icon="fa-solid fa-chevron-right" class="ml-4 mr-4"/>

          <button @click="onChangeFolder(item.item_code)" class="intro-y text-lg font-medium">
            {{ item.item_name }}
          </button>
        </span>

        <font-awesome-icon v-if="item != null" icon="fa-solid fa-chevron-right" class="ml-4 mr-4"/>

        <div class="intro-y text-lg font-medium" v-if="item != null">{{ item.item_name }}</div>
      </div>

    </div>

    <div>
      <div class="box grid grid-cols-12 gap-6 mt-5 p-5">

        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-start flex-col sm:flex-row">
          <div class="flex flex-wrap sm:flex-nowrap items-center justify-end mr-2 mb-1 sm:mb-0">
            <button v-if="!downloading && (!item || item && hasPermission('write', item))" class="btn btn-primary shadow-md" @click="openUploadModal">上載</button>

            <button v-if="!downloading && !modal && (!item || (item && hasPermission('write', item)))" class="btn btn-primary shadow-md ml-2" @click="openCreateFolderModal">
              新增資料夾
            </button>
          </div>
          <div class="flex flex-wrap sm:flex-nowrap items-center justify-end">
            <button v-if="!downloading && selectedItemIds.length < items.length" class="btn btn-secondary shadow-md" @click="selectAllItems">
              全選
            </button>

            <button v-if="!downloading && selectedItemIds.length > 0" class="btn btn-secondary shadow-md ml-2" @click="clearSelectedItems">
              清除選擇
            </button>

            <div v-if="!downloading && selectedItemIds.length > 0" class="flex justify-center items-center action ml-2">
              <button type="button" class="btn btn-primary shadow-md dropdown-toggle" @click="action = !action" @focusout="action = false">
                動作
              </button>

              <div class="relative">
                <div class="dropdown-menu w-40" :class="{'show': action}">
                  <ul class="dropdown-content">
                    <li v-if="checkAllSelectedItemsType('FILE') && checkAllSelectedItemsHavePermission('read')">
                      <button type="button" class="dropdown-item" @click.stop="onMultipleDownload()">
                        <font-awesome-icon icon="fa-solid fa-cloud-arrow-down" class="fa-1x w-5 mr-2"/>
                        下載
                      </button>
                    </li>
                    <li v-if="checkAllSelectedItemsType('DIRECTORY') && checkAllSelectedItemsHavePermission('write') && (modal == null || !modal)">
                      <button type="button" class="dropdown-item" @click.stop="openPermissionModal()">
                        <font-awesome-icon icon="fa-solid fa-sliders" class="fa-1x w-5 mr-2"/>
                        設定權限
                      </button>
                    </li>
<!--                    <li v-if="checkAllSelectedItemsType('DIRECTORY') && (modal == null || !modal)">-->
<!--                      <button type="button" class="dropdown-item" @click.stop="openShareModal()">-->
<!--                        <font-awesome-icon icon="fa-solid fa-share" class="fa-1x w-5 mr-2"/>-->
<!--                        設定分享-->
<!--                      </button>-->
<!--                    </li>-->
<!--                    <li v-if="checkAllSelectedItemsHavePermission('write') && (modal == null || !modal)">-->
<!--                      <button type="button" class="dropdown-item" @click.stop="openTagModal()">-->
<!--                        <font-awesome-icon icon="fa-solid fa-tags" class="fa-1x w-5 mr-2"/>-->
<!--                        管理標籤-->
<!--                      </button>-->
<!--                    </li>-->
                    <li v-if="checkAllSelectedItemsHavePermission('delete') && (modal == null || !modal)">
                      <button type="button" class="dropdown-item" @click.stop="openDeleteModal()">
                        <font-awesome-icon icon="fa-solid fa-trash" class="fa-1x w-5 mr-2"/>
                        刪除
                      </button>
                    </li>
                    <li v-if="!((checkAllSelectedItemsType('FILE') && checkAllSelectedItemsHavePermission('read')) || (checkAllSelectedItemsType('DIRECTORY') && checkAllSelectedItemsHavePermission('write') && (modal == null || !modal)) || checkAllSelectedItemsHavePermission('delete') && (modal == null || !modal) )">
                      沒有動作
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <span v-show="downloading"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>

          <div class="hidden md:block mx-auto text-slate-500">正在顯示第 {{ items.length }} 個檔案</div>

          <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0 flex">
            <div class="mr-2 flex-basis-50 sm:flex-basis-0 sm:w-auto">
              <select id="tag-filter" v-model="tag" class="form-select w-auto" aria-label="請選擇">
                <option value="">--- 請選擇 ---</option>
                <option v-for="(item, key) in levelTags" :key="key" :value="item.tag_name.trim()">{{ item.tag_name.replace(' ', '&nbsp;') }}</option>
              </select>
            </div>

            <div class="w-56 relative text-slate-500 ml-2">
              <input v-model="search" type="text" class="form-control sm:w-56 pr-10" placeholder="搜尋">
              <font-awesome-icon icon="fa-solid fa-magnifying-glass"
                                 class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0"/>
            </div>
          </div>
        </div>

        <!-- BEGIN: Data List -->
        <div class="intro-y col-span-12 overflow-auto min-h-screen">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="whitespace-nowrap">選取</th>
              <th class="whitespace-nowrap"><span v-if="search !== ''">類型</span><order-button v-else title="類型" column="file_format_id" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><span v-if="search !== ''">名稱</span><order-button v-else title="名稱" column="item_name" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="text-center whitespace-nowrap"></th>
              <th class="whitespace-nowrap"><span v-if="search !== ''">擁有者</span><order-button v-else title="擁有者" column="user_id" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><span v-if="search !== ''">上次修改日期</span><order-button v-else title="上次修改日期" column="updated_at" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><span v-if="search !== ''">版本</span><order-button v-else title="版本" column="file_versions_count" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
              <th class="whitespace-nowrap"><span v-if="search !== ''">檔案大小</span><order-button v-else title="檔案大小" column="file_size" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
            </tr>
            </thead>

            <tbody>
            <tr class="" v-for="(item, key) in items" :key="key" @click.stop="onOpen(item)"
             :class="{'invisible': item.is_hidden}">
              <td><input class="form-check-input border border-slate-500" type="checkbox" @click.stop="onSelect(item)" :value="item.item_id" v-model="selectedItemIds"></td>
              <td class="">
                <div class="item-icon bg-contain"
                     :class="{'folder': item.item_type === 'DIRECTORY', 'file': item.item_type === 'FILE'}"><span
                  v-if="item.item_type === 'FILE'">{{ getExtension(item) }}</span></div>
              </td>
              <td class="">
                {{ item.item_name ?? '-' }}
              </td>
              <td
                class="table-report__action w-56">
                <div class="flex justify-center items-center action">
                  <button v-if="!hasNoPermission(item)" type="button" class="dropdown-toggle w-5 h-5 block" @click.stop="onClick(item)"
                          @focusout="onUnselect" :tabindex="key">
                    <font-awesome-icon icon="fa-solid fa-ellipsis-vertical"/>
                  </button>

                  <div class="relative">
                    <div class="dropdown-menu w-40" :class="{'show': item.item_id === currentItemId}">
                      <ul class="dropdown-content">
                        <li v-if="item.item_type === 'FILE' && isViewable(item) && hasPermission('read', item)">
                          <button type="button" class="dropdown-item" @click.stop="onOpen(item)">
                            <font-awesome-icon icon="fa-solid fa-eye" class="fa-1x w-5 mr-2"/>
                            檢視
                          </button>
                        </li>
                        <li v-if="item.item_type === 'FILE' && hasPermission('read', item)">
                          <button type="button" class="dropdown-item" @click.stop="onDownload(item)">
                            <font-awesome-icon icon="fa-solid fa-cloud-arrow-down" class="fa-1x w-5 mr-2"/>
                            下載
                          </button>
                        </li>
                        <li v-if="item.item_type === 'DIRECTORY' && hasPermission('read', item)">
                          <button type="button" class="dropdown-item" @click.stop="onOpen(item)">
                            <font-awesome-icon icon="fa-solid fa-folder-open" class="fa-1x w-5 mr-2"/>
                            開啟
                          </button>
                        </li>
                        <li v-if="hasPermission('write', item) && (modal == null || !modal)">
                          <button type="button" class="dropdown-item" @click.stop="openRenameModal(item)">
                            <font-awesome-icon icon="fa-solid fa-file-pen" class="fa-1x w-5 mr-2"/>
                            重新命名
                          </button>
                        </li>
                        <li v-if="hasPermission('write', item) && (modal == null || !modal)">
                          <button type="button" class="dropdown-item" @click.stop="openPermissionModal(item)">
                            <font-awesome-icon icon="fa-solid fa-sliders" class="fa-1x w-5 mr-2"/>
                            設定權限
                          </button>
                        </li>
                        <li v-if="hasPermission('write', item) && (modal == null || !modal)">
                          <button type="button" class="dropdown-item" @click.stop="openShareModal(item)">
                            <font-awesome-icon icon="fa-solid fa-share" class="fa-1x w-5 mr-2"/>
                            設定分享
                          </button>
                        </li>
                        <li v-if="(modal == null || !modal) && hasPermission('write', item)">
                          <button type="button" class="dropdown-item" @click.stop="onCreateShareCode(item)">
                            <font-awesome-icon icon="fa-solid fa-share" class="fa-1x w-5 mr-2"/>
                            分享
                          </button>
                        </li>
                        <li v-if="item.item_type === 'FILE' && hasPermission('write', item) && (modal == null || !modal)">
                          <button type="button" class="dropdown-item" @click.stop="openVersionModal(item)">
                            <font-awesome-icon icon="fa-solid fa-code-fork" class="fa-1x w-5 mr-2"/>
                            檢視版本
                          </button>
                        </li>
                        <li v-if="hasPermission('write', item) && (modal == null || !modal)">
                          <button type="button" class="dropdown-item" @click.stop="openTagModal(item)">
                            <font-awesome-icon icon="fa-solid fa-tags" class="fa-1x w-5 mr-2"/>
                            管理標籤
                          </button>
                        </li>
                        <li v-if="hasPermission('write', item) && (checkPermission(permissions, 'site.media-library.manage') || item.user_id == profile.user_id) && item.is_hidden && (modal == null || !modal)">
                          <button type="button" class="dropdown-item" @click.stop="onVisible(item)">
                            <font-awesome-icon icon="fa-solid fa-eye" class="fa-1x w-5 mr-2"/>
                            顯示
                          </button>
                        </li>
                        <li v-if="hasPermission('write', item) && (checkPermission(permissions, 'site.media-library.manage') || item.user_id == profile.user_id) && !item.is_hidden && (modal == null || !modal)">
                          <button type="button" class="dropdown-item" @click.stop="onInvisible(item)">
                            <font-awesome-icon icon="fa-solid fa-eye-slash" class="fa-1x w-5 mr-2"/>
                            隱藏
                          </button>
                        </li>
                        <li v-if="hasPermission('delete', item) && (modal == null || !modal)">
                          <button type="button" class="dropdown-item" @click.stop="openDeleteModal(item)">
                            <font-awesome-icon icon="fa-solid fa-trash" class="fa-1x w-5 mr-2"/>
                            刪除
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <button v-if="item.item_type === 'FILE' && modal && (modalFilter == null || checkModalFilter(item))" @click.stop="$emit('select', item)" class="btn btn-primary ml-2">選擇</button>
                </div>
              </td>
              <td><div class="w-56">{{ item.owner != null ? item.owner.display_name : '-' }}</div></td>
              <td>{{ item.updated_at ?? '-' }}</td>
              <td>{{ item.file_versions_count != null ? (item.file_versions_count) : '-' }}</td>
              <td>{{ item.item_type === 'FILE' ? getFileSize(item.file.file_size) : '' }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->

        <!-- BEGIN: Upload Modal -->
        <div id="upload-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="font-medium text-base mr-auto">
                  上載檔案
                </h2>
              </div>
              <div class="modal-body p-0">
                <div class="p-5">
                  <div class="text-slate-500 mt-2">
                    <form data-single="true" action="/" class="dropzone dropzoneAll mt-2">
                      <div class="fallback"><input name="file" type="file"/></div>
                      <div class="dz-message" data-dz-message>
                        <div class="text-lg font-medium">將檔案拖放到此處或單擊以上傳</div>
                        <div class="text-slate-500"> 檔案上限大小：100MB</div>
                      </div>
                    </form>
                  </div>
                </div>

                <div class="px-5 pb-8 text-center">
                  <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24">取消
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Upload Modal -->

        <!-- BEGIN: Create Folder Modal -->
        <div id="create-folder-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="font-medium text-base mr-auto">
                  新增資料夾
                </h2>
              </div>
              <div class="modal-body p-0">
                <div class="p-5">
                  <div class="text-slate-500 mt-2">
                    <div class="">
                      <label class="form-label">名稱</label>
                      <input type="text" v-model="form.item_name" placeholder="請輸入名稱" class="form-control w-full"/>
                    </div>

                    <div class="mt-2">
                      <label class="form-label">簡介</label>
                      <input type="text" v-model="form.description" placeholder="請輸入簡介"
                             class="form-control w-full"/>
                    </div>
                  </div>
                </div>
                <div class="px-5 pb-8 text-center">
                  <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消
                  </button>
                  <button type="button" data-tw-dismiss="modal" class="btn btn-primary w-24 text-white"
                          :disabled="form.item_name == null || form.item_name === ''"
                          @click="onCreateFolder">確定
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Create Folder Modal -->

        <!-- BEGIN: View Modal -->
        <div id="view-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="flex justify-between align-items-center view-toolbar">
            <button type="button" @click="onDownload(viewItem)" class="btn-download text-white">
              <font-awesome-icon icon="fa-solid fa-cloud-arrow-down" class="fa-2x"/>
            </button>

            <div class="text-white">{{ viewItem != null ? viewItem.file.file_name : ''}}</div>

            <button type="button" data-tw-dismiss="modal" class="btn-close text-white">
              <font-awesome-icon icon="fa-solid fa-xmark" class="fa-2x"/>
            </button>
          </div>

          <div class="modal-dialog">
            <img v-if="viewItem != null && viewItem.file && viewItem.file.format && viewItem.file.format.mime_type.startsWith('image/')" :src="viewItem.view_url">
          </div>

          <div v-if="viewItem != null && viewItem.file && viewItem.file.format && !viewItem.file.format.mime_type.startsWith('image/')">
            <embed v-if="viewItem.file.format.mime_type === 'application/pdf'" :src="viewItem.view_url" class="iframe-viewer"/>
            <iframe
              v-else-if="isViewable(viewItem)"
              :src="`https://view.officeapps.live.com/op/embed.aspx?src=${viewItem.view_url}`" frameborder="0"
              class="iframe-viewer"></iframe>
          </div>

        </div>
        <!-- END: View Modal -->

        <!-- BEGIN: Rename Modal -->
        <div id="rename-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="font-medium text-base mr-auto">
                  重新命名
                </h2>
              </div>
              <div class="modal-body p-0">
                <div class="p-5">
                  <div class="text-slate-500">
                    <div class="">
                      <label class="form-label">名稱</label>
                      <input type="text" v-model="form.item_name" placeholder="請輸入名稱" class="form-control w-full"/>
                    </div>

                    <div class="mt-2">
                      <label class="form-label">簡介</label>
                      <input type="text" v-model="form.description" placeholder="請輸入簡介"
                             class="form-control w-full"/>
                    </div>
                  </div>
                </div>
                <div class="px-5 pb-8 text-center">
                  <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消
                  </button>
                  <button type="button" data-tw-dismiss="modal" class="btn btn-primary w-24 text-white"
                          :disabled="form.item_name == null || form.item_name === ''"
                          @click="onRename">確定
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Rename Modal -->

        <!-- BEGIN: Permission Modal -->
        <div id="permission-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="font-medium text-base mr-auto">
                  設定權限
                </h2>
              </div>
              <div class="modal-body p-0">
                <div class="p-5">
                  <div class="text-slate-500">
                    <div class="">
                      <label class="form-label">權限</label>
                      <select v-model="form.site_permission" class="form-select" aria-label="請選擇權限">
                        <option v-for="(item, key) in folderPermissions" :key="key" :value="item.value">{{
                            item.key
                          }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="px-5 pb-8 text-center">
                  <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消
                  </button>
                  <button type="button" data-tw-dismiss="modal" class="btn btn-primary w-24 text-white"
                          @click="onSetPermission">確定
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Permission Modal -->

        <!-- BEGIN: Share Modal -->
        <div id="share-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-xl ">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="font-medium text-base mr-auto">
                  設定分享
                </h2>
              </div>
              <div class="modal-body p-0">
                <div class="p-5">
                  <div class="text-slate-500">
                    <div>
                      <div class="mt-3 flex">
                        <button type="button" class="flex-1 btn mr-2" :class="{'btn-primary': shareType === 'user', 'btn-secondary': shareType !== 'user'}" @click="shareType = 'user'">用戶</button>
                        <button type="button" class="flex-1 btn" :class="{'btn-primary': shareType === 'group', 'btn-secondary': shareType !== 'group'}" @click="shareType = 'group'">組別</button>
                      </div>

                      <div v-if="shareType === 'user'">
                        <div v-if="selectUser == null" class="mt-3">
                          <label class="form-label">選擇用戶</label>
                          <multi-select v-model="userIds" :options="userOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true" placeholder="以名搜尋" selectLabel="按下Enter以選擇" label="searchLabel" track-by="email" :preselect-first="false" :hide-selected="true" >
                            <template slot="noResult"><span class="multiselect__option">沒有結果，請輸入其他字</span></template>
                          </multi-select>
                        </div>

                        <div v-else class="mt-3">
                          <label class="form-label">用戶</label>
                          <div>{{ getObjectValue(users, 'user_id', selectUser.user_id, 'first_name') }} {{ getObjectValue(users, 'user_id', selectUser.user_id, 'last_name')}}</div>
                        </div>
                      </div>

                      <div v-if="shareType === 'group'">
                        <div v-if="selectGroup === null" class="mt-3">
                          <label class="form-label">選擇組別</label>
                          <multi-select v-model="userGroupIds" :options="userGroupOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true" placeholder="以名搜尋" selectLabel="按下Enter以選擇" label="name" track-by="group_id" :preselect-first="false" :hide-selected="true" >
                            <template slot="noResult"><span class="multiselect__option">沒有結果，請輸入其他字</span></template>
                          </multi-select>
                        </div>

                        <div v-else class="mt-3">
                          <label class="form-label">組別</label>
                          <div>{{ getObjectValue(userGroups, 'group_id', selectGroup.group_id, 'name') }} {{ getObjectValue(userGroups, 'group_id', selectGroup.group_id, 'last_name')}}</div>
                        </div>
                      </div>

                      <div class="mt-3">
                        <label for="role-id" class="form-label">權限</label>
                        <select v-model="form.permission" id="role-id" class="form-select mt-2 sm:mr-2" aria-label="Default select example">
                          <option value="">--- 請選擇 ---</option>
                          <option v-for="(item, key) in folderPermissions" :key="key" :value="item.value">{{ item.key }}</option>
                        </select>
                      </div>

                      <button v-if="(shareType === 'user' && selectUser == null) || (shareType === 'group' && selectGroup == null)" type="button" class="btn btn-primary w-24 text-white mt-3"
                              :disabled="form.permission === '' || (userIds.length === 0 && userGroupIds.length === 0)"
                              @click="onAddShare">新增
                      </button>

                      <button v-else type="button" class="btn btn-pending w-24 text-white mt-3"
                              :disabled="form.permission === ''"
                              @click="onUpdateShare">更改
                      </button>
                    </div>

                    <div v-if="shareType === 'user'">
                      <label class="mt-4 form-label">已分享的用戶</label>
                    </div>

                    <div v-if="shareType === 'group'">
                      <label class="mt-4 form-label">已分享的組別</label>
                    </div>

                    <div class="flex justify-between">
                      <button type="button" class="btn flex-1" :class="{'btn-primary': selectedFolderPermission === '', 'btn-secondary': selectedFolderPermission !== ''}" @click="selectedFolderPermission = ''">全部</button>
                      <button type="button" v-for="(item, key) in folderPermissions" :key="key" class="btn flex-1 ml-1" :class="{'btn-primary': selectedFolderPermission === item.value, 'btn-secondary': selectedFolderPermission !== item.value }" @click="selectedFolderPermission = item.value">{{ item.key }}</button>
                    </div>

                    <div v-if="shareType === 'user'">
                      <div class="grid grid-cols-12 gap-6 mt-4">
                        <div v-for="(item, key) in shareFilteredUsers" :key="key" class="intro-y col-span-12 md:col-span-6">
                          <div class="box">
                            <div class="flex flex-col lg:flex-row items-center p-5">
                              <div class="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
                                <img alt="" class="rounded-full" :src="item.avatar_url != null && item.avatar_url !== '' ? item.avatar_url : '/images/user.png'">
                              </div>
                              <div class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
                                <button type="button" @click="goToProfile(item.user_id)" class="font-medium">{{ getObjectValue(users, 'user_id', item.user_id, 'display_name') }}</button>
                                <div class="text-slate-500 text-xs mt-0.5">{{ getObjectValue(folderPermissions, 'value', item.permission, 'key') }}</div>
                              </div>
                              <div class="flex mt-4 lg:mt-0" v-if="selectUser == null && item.id !== null">
                                <button class="btn btn-outline-pending py-1 px-2 mr-2" @click="onStartUpdateShare(item)" v-if="hasPermission('write', selectItem)">更改</button>
                                <button class="btn btn-outline-danger py-1 px-2" @click="onRemoveShare(item)" v-if="hasPermission('write', selectItem)">移除</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="shareFilteredUsers.length === 0" class="text-center p-6 mt-5">
                        沒有用戶
                      </div>
                    </div>

                    <div v-if="shareType === 'group'">
                      <div class="grid grid-cols-12 gap-6 mt-4">
                        <div v-for="(item, key) in shareFilteredGroups" :key="key" class="intro-y col-span-12 md:col-span-6">
                          <div class="box">
                            <div class="flex flex-col lg:flex-row items-center p-5">
                              <div class="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
                                <img alt="" class="rounded-full" :src="item.avatar_url != null && item.avatar_url !== '' ? item.avatar_url : '/images/user.png'">
                              </div>
                              <div class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
                                <button type="button" @click="goToProfile(item.user_id)" class="font-medium">{{ getObjectValue(userGroups, 'group_id', item.group_id, 'name')}}</button>
                                <div class="text-slate-500 text-xs mt-0.5">{{ getObjectValue(folderPermissions, 'value', item.permission, 'key') }}</div>
                              </div>
                              <div class="flex mt-4 lg:mt-0" v-if="selectGroup == null">
                                <button class="btn btn-outline-pending py-1 px-2 mr-2" @click="onStartUpdateShareGroup(item)" v-if="hasPermission('write', selectItem)">更改</button>
                                <button class="btn btn-outline-danger py-1 px-2" @click="onRemoveShareGroup(item)" v-if="hasPermission('write', selectItem)">移除</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="shareFilteredGroups.length === 0" class="text-center p-6 mt-5">
                        沒有組別
                      </div>
                    </div>
                  </div>
                </div>
                <div class="px-5 pb-8 text-center">
                  <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">關閉
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Share Modal -->

        <!-- BEGIN: Version Modal -->
        <div id="version-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-xl ">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="font-medium text-base mr-auto">
                  檢視版本
                </h2>
              </div>
              <div class="modal-body p-0">
                <div class="p-5">
                  <div class="text-slate-500">
                    <div>
                      <label class="form-label">上載新版本</label>
                      <form data-single="true" action="/" class="dropzone dropzoneOne mt-2">
                        <div class="fallback"><input name="file" type="file"/></div>
                        <div class="dz-message" data-dz-message>
                          <div class="text-lg font-medium">將檔案拖放到此處或單擊以上傳</div>
                          <div class="text-slate-500"> 檔案上限大小：100MB</div>
                        </div>
                      </form>
                    </div>

                    <label class="mt-4 form-label">所有版本</label>

                    <div class="overflow-x-auto">
                      <table class="table table-striped">
                        <thead>
                        <tr>
                          <th class="whitespace-nowrap">版本</th>
                          <th class="whitespace-nowrap">名稱</th>
                          <th class="whitespace-nowrap">上載者</th>
                          <th class="whitespace-nowrap">版本日期</th>
                          <th class="whitespace-nowrap">檔案大小</th>
                          <th class="text-center whitespace-nowrap"></th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr class="" v-for="(item, key) in itemVersions" :key="key">
                          <td>{{ itemVersions.length - key }}</td>
                          <td>{{ item.file_name ?? '-' }}</td>
                          <td>{{ item.owner.full_name ?? '-' }}</td>
                          <td>{{ item.created_at ?? '-' }}</td>
                          <td>{{ getFileSize(item.file_size) }}</td>
                          <td
                            class="table-report__action w-56">
                            <button v-if="hasPermission('read', item)" type="button" @click="onDownload(selectItem, item.file_name, item.version_id)"
                                    class="flex items-center mr-3 text-primary">
                              <font-awesome-icon icon="fa-solid fa-cloud-arrow-down"/>
                              &nbsp;下載
                            </button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="px-5 pb-8 text-center">
                  <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">關閉
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Version Modal -->

        <!-- BEGIN: Tag Modal -->
        <div id="tag-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog modal-xl ">
            <div class="modal-content">
              <div class="modal-header">
                <h2 class="font-medium text-base mr-auto">
                  管理標籤
                </h2>
              </div>
              <div class="modal-body p-0">
                <div class="p-5">
                  <div class="text-slate-500">
                    <div>
                      <label class="form-label">新增標籤</label>
                      <multi-select v-model="form.tags" :options="tagOptions" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true" placeholder="以名搜尋" selectLabel="按下Enter以選擇" label="tag_name" track-by="tag_id" :preselect-first="true" :hide-selected="true" >
                        <template slot="noResult"><span class="multiselect__option">沒有結果，請輸入其他字</span></template>
                      </multi-select>

                      <button v-if="selectUser == null" type="button" class="btn btn-primary w-24 text-white mt-3"
                              :disabled="form.tags == null || form.tags.length === 0"
                              @click="onAddItemTags">新增
                      </button>
                    </div>

                    <label class="mt-4 form-label">所有標籤</label>

                    <div class="grid grid-cols-12 gap-5 mt-5">
                      <div class="col-span-12 sm:col-span-4 2xl:col-span-3 box p-5 flex justify-between align-items-center" v-for="(item, key) in itemTags" :key="key">
                        <div class="font-medium text-base">{{ item.tag_name }}</div>
                        <button class="btn btn-outline-danger py-1 px-2" @click="onRemoveItemTag(item.tag_id)">移除</button>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="px-5 pb-8 text-center">
                  <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">關閉
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Tag Modal -->

        <!-- BEGIN: Delete Confirmation Modal -->
        <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body p-0">
                <div class="p-5 text-center">
                  <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger" />
                  <div class="text-3xl mt-5">你確定嗎？</div>
                  <div class="text-slate-500 mt-2">
                    你是否要刪除此檔案？
                    <br>
                    這動作不能還原。
                  </div>
                </div>
                <div class="px-5 pb-8 text-center">
                  <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                  <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Delete Confirmation Modal -->

      </div>
    </div>
  </div>
</template>

<script>
import { partial } from 'filesize'

export default {
  props: ['auth', 'currentSite', 'permissions', 'profile', 'modal', 'modalFilter'],
  data() {
    return {
      mounted: false,

      host: '',

      folderId: 0,
      folderCode: '',
      parentFolders: [],
      item: null,
      items: [],
      currentItemId: null,
      levelTags: [],

      downloading: false,
      clicking: false,

      tag: '',
      search: '',
      orderBy: '',
      order: '',

      userIds: [],
      userGroupIds: [],

      form: {
        folder_id: null,
        item_name: null,
        description: null,
        site_permission: '',
        user_id: null,
        group_id: null,
        permission: '',
        tags: null
      },

      folderPermissions: [
        { value: 'full', key: '可讀寫刪' },
        { value: 'writable', key: '可讀寫' },
        { value: 'readable', key: '可讀' },
        { value: 'restricted', key: '限制： 不可讀寫刪' }
      ],

      selectItem: null,
      viewItem: null,

      itemVersions: [],
      users: [],
      userGroups: [],
      shareUsers: [],
      shareFilteredUsers: [],
      shareGroups: [],
      shareFilteredGroups: [],
      userOptions: [],
      userGroupOptions: [],
      selectUser: null,
      groupOptions: [],
      selectGroup: null,

      // shareItem: null,
      selectedFolderPermission: '',

      itemTags: [],
      tagOptions: [],

      selectedItemIds: [],
      action: false,

      shareType: 'user'
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite(val) {
      this.init()
    },
    '$route'(val) {
      this.init()
    },
    search(val) {
      if (val == null || val === '') {
        this.loadLibraryItems()
      } else {
        this.tag = ''
        this.orderBy = ''
        this.order = ''
        this.onSearch()
        if (this.$route.path !== '/library') {
          this.$router.push('/library')
        }
      }
    },
    tag(val) {
      if (val == null || val === '') {
        this.loadLibraryItems()
      } else {
        this.search = ''
        this.orderBy = ''
        this.order = ''
        this.onSearch()
        if (this.$route.path !== '/library') {
          this.$router.push('/library')
        }
      }
    },
    order() {
      this.loadLibraryItems()
    },
    selectedFolderPermission() {
      this.onFilterShare()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      this.host = window.location.protocol + '//' + window.location.host
      this.parentFolders = []
      this.item = null
      this.folderCode = this.$route.params.folderCode ?? ''

      if (this.currentSite) {
        this.loadUsers()
        this.loadUserGroups()
        this.loadTags()
        await this.getItem()
        await this.loadLibraryItems(true)
      }

      if (!this.mounted) {
        window.tailwind.svgLoader()
        this.$nextTick(() => {
          this.dropZoneAll()
          this.dropZoneOne()

          const self = this

          this.$nextTick(() => {
            this.$dz.on('addedfile', async function(file) {
              await self.onUpload(file)
            })

            this.$dz.on('addedfiles', function() {
              const el = document.querySelector('#upload-modal')
              const modal = window.tailwind.Modal.getOrCreateInstance(el)
              modal.hide()

              self.$dz.removeAllFiles()
            })

            this.$dz1.on('addedfile', async function(file) {
              await self.onUploadNewVersion(file)
              self.$dz1.removeAllFiles()
            })
          })
        })
      }

      this.mounted = true
    },
    async onChangeFolder(folderCode = '') {
      this.parentFolders = []
      this.item = null
      this.folderCode = folderCode

      if (this.currentSite) {
        await this.getItem()
        await this.loadLibraryItems(true)
      }
    },
    async getItem() {
      if (this.folderCode !== '') {
        const response = await this.getLibraryItem(this.currentSite.site_id, this.folderCode)

        if (response.code === 0) {
          this.item = response.data
          this.folderId = this.item.item_id
        } else {
          this.$router.push('/library')
        }
      } else {
        this.folderId = 0
      }
    },
    async loadLibraryItems(getParent = false) {
      const response = await this.getLibraryItems(this.currentSite.site_id, this.folderCode, this.orderBy, this.order)

      if (response.code === 0) {
        if (getParent) {
          this.getAllParentFolders(response.parentFolder)
        }

        this.items = response.data
      }
    },
    async getAllParentFolders(parentFolder) {
      if (parentFolder != null) {
        this.parentFolders.push(parentFolder)

        const response = await this.getLibraryItems(this.currentSite.site_id, parentFolder.item_code)

        if (response.code === 0) {
          this.getAllParentFolders(response.parentFolder)
        }
      } else {
        this.parentFolders = this.parentFolders.reverse()
      }
    },
    getFileSize(fileSize) {
      const size = partial({ base: 10, standard: 'jedec' })
      return size(fileSize)
    },
    onOpen(item) {
      if (this.clicking) {
        return
      }

      this.clicking = true

      if (!this.hasPermission('read', item)) {
        return
      }

      this.selectedItemIds = []
      this.action = false

      if (item.item_type === 'DIRECTORY') {
        if (this.modal) {
          this.onChangeFolder(item.item_code)
        } else {
          this.$router.push(`/library/${item.item_code}`)
        }
      } else {
        this.openViewModal(item)
      }

      setTimeout(() => {
        this.clicking = false
        this.search = null
      }, 1000)
    },
    onClick(item) {
      this.currentItemId = item.item_id
    },
    onUnselect() {
      this.currentItemId = null
    },
    async onSearch() {
      this.items = await this.searchLibraryItems(this.currentSite.site_id, this.search, this.tag)
    },
    openUploadModal() {
      const el = document.querySelector('#upload-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onUpload(file) {
      const response = await this.uploadLibraryItem(this.currentSite.site_id, this.folderId, file)

      let option = {
        title: '成功',
        message: `已上載檔案：${file.name}`,
        type: 'success'
      }

      if (response.code === 0) {
        this.loadLibraryItems()
      } else {
        option = {
          title: `未能上載檔案：${file.name}`,
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    },
    openCreateFolderModal() {
      const el = document.querySelector('#create-folder-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onCreateFolder() {
      const el = document.querySelector('#create-folder-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      this.form.folder_id = this.folderId
      const response = await this.createLibraryFolder(this.currentSite.site_id, this.form)

      let option = {
        title: '成功',
        message: '已新增資料夾',
        type: 'success'
      }

      if (response.code === 0) {
        this.loadLibraryItems()
      } else {
        option = {
          title: '未能新增資料夾',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)

      this.form.item_name = null
      this.form.description = null
    },
    getHighestPermission(permission1, permission2) {
      if (permission1 === 'restricted' || permission2 === 'restricted') {
        return 'restricted'
      } if (permission1 === 'full' || permission2 === 'full') {
        return 'full'
      } else if (permission1 === 'writable' || permission2 === 'writable') {
        return 'writable'
      } else if (permission1 === 'readable' || permission2 === 'readable') {
        return 'readable'
      }
      return permission1
    },
    hasPermission(permission, item) {
      if (item.user_permission === 'restricted') {
        return false
      }

      switch (permission) {
        case 'read':
          switch (item.user_permission) {
            case 'full': return true
            case 'readable': return true
            case 'writable': return true
            default: return false
          }
        case 'write':
          switch (item.user_permission) {
            case 'full': return true
            case 'writable': return true
            default: return false
          }
        case 'delete':
          switch (item.user_permission) {
            case 'full': return true
            default: return false
          }
      }

      // if (item.site_permission === 'restricted') {
      //   return false
      // } else if (item.site_permission === '') {
      //   return true
      // }
      //
      // switch (permission) {
      //   case 'read':
      //     switch (item.site_permission) {
      //       case 'full': return true
      //       case 'readable': return true
      //       default: return false
      //     }
      //   case 'write':
      //     switch (item.site_permission) {
      //       case 'full': return true
      //       case 'writable': return true
      //       default: return false
      //     }
      //   case 'delete':
      //     switch (item.site_permission) {
      //       case 'full': return true
      //       default: return false
      //     }
      // }

      return false
    },
    hasNoPermission(item) {
      if (item.user_permission === 'restricted') {
        return true
      }

      // else if (item.site_permission === 'restricted') {
      //   return true
      // }

      return false
    },
    async openViewModal(item) {
      const response = await this.getLibraryItem(this.currentSite.site_id, item.item_code)

      if (response.code === 0) {
        this.viewItem = response.data

        if (this.isViewable(item)) {
          const el = document.querySelector('#view-modal')
          const modal = window.tailwind.Modal.getOrCreateInstance(el)
          modal.show()
        } else {
          this.onDownload(item)
        }
      }
    },
    async onDownload(item, filename = null, versionId = '') {
      this.downloading = true
      const response = await this.downloadLibraryItem(this.currentSite.site_id, item.item_code, versionId)
      const blob = new Blob([response], { type: item.file.format != null ? item.file.format.mime_type : null })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename ?? item.file.file_name
      link.click()
      URL.revokeObjectURL(link.href)
      this.downloading = false
    },
    async onVisible(item) {
      this.selectItem = item

      const response = await this.updateLibraryItemVisibility(this.currentSite.site_id, this.selectItem.item_code, { is_hidden: false })

      if (response.code === 0) {
        this.loadLibraryItems()
      } else {
        this.$notify({
          title: '未能顯示',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        })
      }
    },
    async onInvisible(item) {
      this.selectItem = item

      const response = await this.updateLibraryItemVisibility(this.currentSite.site_id, this.selectItem.item_code, { is_hidden: true })

      if (response.code === 0) {
        this.loadLibraryItems()
      } else {
        this.$notify({
          title: '未能隱藏',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        })
      }
    },
    openRenameModal(item) {
      this.selectItem = item

      this.form.item_name = this.selectItem.item_name
      this.form.description = this.selectItem.description

      const el = document.querySelector('#rename-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onRename() {
      const el = document.querySelector('#rename-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      this.form.folder_id = this.folderId
      const response = await this.updateLibraryItem(this.currentSite.site_id, this.selectItem.item_code, this.form)

      let option = {
        title: '成功',
        message: '已重新命名',
        type: 'success'
      }

      if (response.code === 0) {
        this.loadLibraryItems()
      } else {
        option = {
          title: '未能重新命名',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)

      this.form.item_name = null
      this.form.description = null
    },
    openPermissionModal(item) {
      this.selectItem = item

      if (this.selectItem != null) {
        this.form.site_permission = this.selectItem.site_permission
      } else {
        this.form.site_permission = 'full'
      }

      const el = document.querySelector('#permission-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onSetPermission() {
      const el = document.querySelector('#permission-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      if (this.selectItem != null) {
        const response = await this.updateFolderPermission(this.currentSite.site_id, this.selectItem.item_code, this.form)

        let option = {
          title: '成功',
          message: '已設定權限',
          type: 'success'
        }

        if (response.code === 0) {
          this.loadLibraryItems()
        } else {
          option = {
            title: '未能設定權限',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      } else if (this.selectedItemIds.length > 0) {
        let option = {
          title: '成功',
          message: '已設定權限',
          type: 'success'
        }

        for (let i = 0; i < this.selectedItemIds.length; i++) {
          const item = this.getSelectedItem(this.selectedItemIds[i])
          if (item) {
            const response = await this.updateFolderPermission(this.currentSite.site_id, item.item_code, this.form)

            if (response.code === 0) {
              console.log('Success')
            } else {
              option = {
                title: '未能設定權限',
                message: response.data.message ?? '系統錯誤，請稍後再試',
                type: 'error'
              }
              break
            }
          }
        }

        this.selectedItemIds = []

        this.$notify(option)
        this.loadLibraryItems()
      }

      this.form.item_name = null
      this.form.description = null
    },
    async loadUsers(resetCurrentPage = true) {
      const response = await this.getSiteUsers(this.currentSite.site_id, '', 99999999, this.currentPage)
      const users = response.data

      for (let i = 0; i < users.length; i++) {
        let centerText = ''
        if (users[i].centre != null) {
          centerText = `[${users[i].centre.abbrev}]`
        }

        users[i].searchLabel = `${users[i].staff_number ?? 'NO STAFF NUMBER'} - ${users[i].full_name} (${users[i].email}) ${centerText}`
      }

      this.users = users
    },
    async loadUserGroups() {
      this.userGroups = await this.getUserGroups(this.currentSite.site_id)
    },
    async openShareModal(item) {
      this.selectItem = item
      this.selectUser = null
      this.selectGroup = null
      this.userIds = []
      this.form.user_id = null
      this.form.permission = ''

      await this.onLoadShareEnrollments(item)

      const el = document.querySelector('#share-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onLoadShareEnrollments(item) {
      this.shareUsers = await this.getFolderShares(this.currentSite.site_id, item.item_code)
      this.onFilterShare()

      this.userOptions = this.users.filter((x) => {
        for (let i = 0; i < this.shareUsers.length; i++) {
          if (this.shareUsers[i].user_id === x.user_id) {
            return false
          }
        }

        return true
      })

      this.shareGroups = await this.getFolderShareGroups(this.currentSite.site_id, item.item_code)
      this.onFilterShare()

      this.userGroupOptions = this.userGroups.filter((x) => {
        for (let i = 0; i < this.shareGroups.length; i++) {
          if (this.shareGroups[i].group_id === x.group_id) {
            return false
          }
        }

        return true
      })

      for (let i = 0; i < this.shareGroups.length; i++) {
        const response = await this.getUserGroup(this.currentSite.site_id, this.shareGroups[i].group_id)
        if (response.code === 0) {
          for (let j = 0; j < response.data.enrollments.length; j++) {
            let userExist = false
            let k = 0
            for (k = 0; k < this.shareUsers.length; k++) {
              if (this.shareUsers[k].user_id === response.data.enrollments[j].user_id) {
                userExist = true
                break
              }
            }

            if (userExist) {
              this.shareUsers[k].permission = this.getHighestPermission(this.shareUsers[k].permission, this.shareGroups[i].permission)
            } else {
              this.shareUsers.push({
                id: null,
                user_id: response.data.enrollments[j].user_id,
                permission: this.shareGroups[i].permission
              })
            }
          }
        }
      }
    },
    onFilterShare() {
      if (this.selectedFolderPermission === '') {
        this.shareFilteredUsers = this.shareUsers
        this.shareFilteredGroups = this.shareGroups
        return
      }

      const shareFilteredUsers = []
      for (let i = 0; i < this.shareUsers.length; i++) {
        if (this.selectedFolderPermission === this.shareUsers[i].permission) {
          shareFilteredUsers.push(this.shareUsers[i])
        }
      }
      this.shareFilteredUsers = shareFilteredUsers

      const shareFilteredGroups = []
      for (let i = 0; i < this.shareGroups.length; i++) {
        if (this.selectedFolderPermission === this.shareGroups[i].permission) {
          shareFilteredGroups.push(this.shareGroups[i])
        }
      }
      this.shareFilteredGroups = shareFilteredGroups
    },
    async onAddShare() {
      let option = {
        title: '成功',
        message: this.shareType === 'user' ? '已分享給用戶' : '已分給用戶組別',
        type: 'success'
      }

      if (this.shareType === 'user') {
        for (let i = 0; i < this.userIds.length; i++) {
          this.form.user_id = this.userIds[i].user_id

          const response = await this.createFolderShare(this.currentSite.site_id, this.selectItem.item_code, this.form)

          if (response.code === 0) {
            console.log('Success')
          } else {
            option = {
              title: '未能分享資料夾給用戶',
              message: response.data.message ?? '系統錯誤，請稍後再試',
              type: 'error'
            }
            break
          }
        }

        this.onLoadShareEnrollments(this.selectItem)
      } else {
        for (let i = 0; i < this.userGroupIds.length; i++) {
          this.form.group_id = this.userGroupIds[i].group_id

          const response = await this.createFolderShareGroup(this.currentSite.site_id, this.selectItem.item_code, this.form)

          if (response.code === 0) {
            console.log('Success')
          } else {
            option = {
              title: '未能分享資料夾給用戶組別',
              message: response.data.message ?? '系統錯誤，請稍後再試',
              type: 'error'
            }
            break
          }
        }

        this.onLoadShareEnrollments(this.selectItem)
      }

      this.$notify(option)

      this.userIds = []
      this.form.user_id = null
      this.userGroupIds = []
      this.form.group_id = null
      this.form.permission = ''
    },
    onStartUpdateShare(item) {
      this.selectUser = item
      this.form.permission = item.permission
    },
    onStartUpdateShareGroup(item) {
      this.selectGroup = item
      this.form.permission = item.permission
    },
    async onUpdateShare() {
      if (this.shareType === 'user') {
        const response = await this.updateFolderShare(this.currentSite.site_id, this.selectItem.item_code, this.selectUser.id, this.form)

        let option = {
          title: '成功',
          message: '已更改用戶分享權限',
          type: 'success'
        }

        if (response.code !== 0) {
          option = {
            title: '未能更改用戶分享權限',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)

        this.selectUser = null
      } else {
        const response = await this.updateFolderShareGroup(this.currentSite.site_id, this.selectItem.item_code, this.selectGroup.id, this.form)

        let option = {
          title: '成功',
          message: '已更改組別分享權限',
          type: 'success'
        }

        if (response.code !== 0) {
          option = {
            title: '未能更改組別分享權限',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)

        this.selectGroup = null
      }

      this.onLoadShareEnrollments(this.selectItem)

      this.form.permission = ''
    },
    async onRemoveShare(item) {
      const response = await this.deleteFolderShare(this.currentSite.site_id, this.selectItem.item_code, item.id)

      let option = {
        title: '成功',
        message: '已移除用戶',
        type: 'success'
      }

      if (response.code !== 0) {
        option = {
          title: '未能移除用戶',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)

      this.onLoadShareEnrollments(this.selectItem)

      this.form.user_id = null
      this.form.permission = ''
    },
    async onRemoveShareGroup(item) {
      const response = await this.deleteFolderShareGroup(this.currentSite.site_id, this.selectItem.item_code, item.id)

      let option = {
        title: '成功',
        message: '已移除組別',
        type: 'success'
      }

      if (response.code !== 0) {
        option = {
          title: '未能移除組別',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)

      this.onLoadShareEnrollments(this.selectItem)

      this.form.group_id = null
      this.form.permission = ''
    },
    async onCreateShareCode(item) {
      const response = await this.createLibraryItemShareCode(this.currentSite.site_id, item.item_code)
      if (response.code === 0) {
        this.onCopyShareLink(item.item_type, response.data)
      } else {
        const option = {
          title: '未能製作分享連結',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }

        this.$notify(option)
      }
    },
    onCopyShareLink(type, item) {
      const tempInput = document.createElement('input')
      if (type === 'FILE') {
        tempInput.value = `${this.host}/library/share/${item.share_code}`
      } else {
        tempInput.value = `${this.host}/library/share-folder/${item.share_code}`
      }

      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)

      const option = {
        title: '成功',
        message: '已複製分享連結',
        type: 'success'
      }

      this.$notify(option)
    },
    async openVersionModal(item) {
      this.selectItem = item

      this.itemVersions = await this.getItemVersions(this.currentSite.site_id, item.item_code)

      const el = document.querySelector('#version-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onUploadNewVersion(file) {
      const response = await this.createItemVersion(this.currentSite.site_id, this.selectItem.item_code, file)

      let option = {
        title: '成功',
        message: '已上載新版本',
        type: 'success'
      }

      if (response.code === 0) {
        this.itemVersions = await this.getItemVersions(this.currentSite.site_id, this.selectItem.item_code)
        this.loadLibraryItems()
      } else {
        option = {
          title: '未能上載新版本',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    },
    async loadTags() {
      const response = await this.getLibraryTags()
      this.levelTags = []
      this.loadRecursiveTags(response, 0)
    },
    loadRecursiveTags(tags, level) {
      for (let i = 0; i < tags.length; i++) {
        let space = ''
        for (let j = 0; j < level; j++) {
          space += '    '
        }
        tags[i].tag_name = space + tags[i].tag_name

        this.levelTags.push(tags[i])
        this.loadRecursiveTags(tags[i].children, level + 1)
      }
    },
    async openTagModal(item) {
      this.selectItem = item

      await this.onLoadLibraryItemTags(item)

      const el = document.querySelector('#tag-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onLoadLibraryItemTags(item) {
      const response = await this.getLibraryItem(this.currentSite.site_id, item.item_code)
      this.itemTags = response.data.tags

      this.tagOptions = this.levelTags.filter((x) => {
        for (let i = 0; i < this.itemTags.length; i++) {
          if (this.itemTags[i].tag_id === x.tag_id) {
            return false
          }
        }

        return true
      })
    },
    async onAddItemTags() {
      for (let i = 0; i < this.form.tags.length; i++) {
        const form = {
          tag_id: this.form.tags[i].tag_id
        }

        const response = await this.addLibraryItemTag(this.currentSite.site_id, this.selectItem.item_code, form)

        if (response.code !== 0) {
          const option = {
            title: `未能新增標籤：${this.form.tags[i].tag_name}`,
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }

          this.$notify(option)
          return
        }
      }

      const option = {
        title: '成功',
        message: '已新增標籤',
        type: 'success'
      }

      this.onLoadLibraryItemTags(this.selectItem)

      this.$notify(option)

      this.form.tags = []
    },
    async onRemoveItemTag(item) {
      const response = await this.removeLibraryItemTag(this.currentSite.site_id, this.selectItem.item_code, item)

      let option = {
        title: '成功',
        message: '已移除標籤',
        type: 'success'
      }

      if (response.code === 0) {
        this.onLoadLibraryItemTags(this.selectItem)
      } else {
        option = {
          title: '未能移除標籤',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)

      this.form.user_id = null
      this.form.permission = ''
    },
    openDeleteModal(item) {
      this.selectItem = item

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      if (this.selectItem != null) {
        const response = await this.deleteLibraryItem(this.currentSite.site_id, this.selectItem.item_code)

        let option = {
          title: '成功',
          message: '檔案已被刪除',
          type: 'success'
        }

        if (response.code === 0) {
          this.loadLibraryItems()
        } else {
          option = {
            title: '未能刪除檔案',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      } else if (this.selectedItemIds.length > 0) {
        let option = {
          title: '成功',
          message: '檔案已被刪除',
          type: 'success'
        }

        for (let i = 0; i < this.selectedItemIds.length; i++) {
          const item = this.getSelectedItem(this.selectedItemIds[i])
          if (item) {
            const response = await this.deleteLibraryItem(this.currentSite.site_id, item.item_code)

            if (response.code === 0) {
              console.log('Success')
            } else {
              option = {
                title: '未能刪除檔案',
                message: response.data.message ?? '系統錯誤，請稍後再試',
                type: 'error'
              }
              break
            }
          }
        }

        this.selectedItemIds = []

        this.$notify(option)
        this.loadLibraryItems()
      }
    },
    onSelect(item) {
    },
    selectAllItems() {
      this.clearSelectedItems()
      for (let i = 0; i < this.items.length; i++) {
        this.selectedItemIds.push(this.items[i].item_id)
      }
    },
    clearSelectedItems() {
      this.selectedItemIds = []
    },
    getSelectedItem(itemId) {
      const index = this.items.findIndex(e => e.item_id === itemId)
      if (index > -1) {
        return this.items[index]
      }
      return null
    },
    checkAllSelectedItemsType(type) {
      for (let i = 0; i < this.selectedItemIds.length; i++) {
        const item = this.getSelectedItem(this.selectedItemIds[i])
        if (item && item.item_type !== type) {
          return false
        }
      }

      return true
    },
    checkAllSelectedItemsHavePermission(permission) {
      for (let i = 0; i < this.selectedItemIds.length; i++) {
        const item = this.getSelectedItem(this.selectedItemIds[i])

        if (item) {
          if (item.user_permission === 'restricted') {
            return false
          }

          switch (permission) {
            case 'read':
              switch (item.user_permission) {
                case 'full': continue
                case 'readable': continue
                case 'writable': continue
                default: return false
              }
            case 'write':
              switch (item.user_permission) {
                case 'full': continue
                case 'writable': continue
                default: return false
              }
            case 'delete':
              switch (item.user_permission) {
                case 'full': continue
                default: return false
              }
          }

          // if (item.site_permission === 'restricted') {
          //   return false
          // } else if (item.site_permission === '') {
          //   continue
          // }

          // switch (permission) {
          //   case 'read':
          //     switch (item.site_permission) {
          //       case 'full': continue
          //       case 'readable': continue
          //       default: return false
          //     }
          //   case 'write':
          //     switch (item.site_permission) {
          //       case 'full': continue
          //       case 'writable': continue
          //       default: return false
          //     }
          //   case 'delete':
          //     switch (item.site_permission) {
          //       case 'full': continue
          //       default: return false
          //     }
          // }
        }
      }

      return true
    },
    async onMultipleDownload() {
      for (let i = 0; i < this.selectedItemIds.length; i++) {
        const item = this.getSelectedItem(this.selectedItemIds[i])
        if (item) {
          await this.onDownload(item)
        }
      }

      this.selectedItemIds = []
    },
    checkModalFilter(item) {
      if (item.file != null && item.file.format && item.file.format.mime_type) {
        for (let i = 0; i < this.modalFilter.length; i++) {
          if (item.file.format.mime_type.startsWith(this.modalFilter[i])) {
            return true
          }
        }
      }
      return false
    },
    goToProfile(userId) {
      setTimeout(() => {
        const el = document.querySelector('#share-modal')
        const modal = window.tailwind.Modal.getOrCreateInstance(el)
        modal.hide()
      }, 100)

      setTimeout(() => {
        this.$router.push(`/users/${userId}`)
      }, 200)
    }
  }
}
</script>

<style lang="scss" scoped>
#library-page {

  &.page {
    margin-bottom: 200px;
  }

  .directory-list {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    .root {
      flex: 0 0 56px;
    }
  }

  .directory-list::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
  }

  table {
    tr {
      &:hover {
        td {
          background-color: rgb(var(--color-primary) / 0.3);
        }
      }

      td {
        padding: 4px 4px 4px 1.25rem;
      }
    }
  }

  .item-icon {
    width: 26px;
    height: 26px;
  }

  .bg-contain {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .folder {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='786' height='786' viewBox='0 0 786 786'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%238a97ac'/%3E%3Cstop offset='1' stop-color='%235d6c83'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Group_3' data-name='Group 3' transform='translate(-567 -93)'%3E%3Crect id='Rectangle_4' data-name='Rectangle 4' width='418' height='681' rx='40' transform='translate(896 109)' fill='%2395a5b9'/%3E%3Crect id='Rectangle_3' data-name='Rectangle 3' width='433' height='681' rx='40' transform='translate(606 93)' fill='%23a0aec0'/%3E%3Crect id='Rectangle_2' data-name='Rectangle 2' width='742' height='734' rx='40' transform='translate(590 145)' fill='%23bec8d9'/%3E%3Crect id='Rectangle_5' data-name='Rectangle 5' width='786' height='692' rx='40' transform='translate(567 187)' fill='url(%23linear-gradient)'/%3E%3C/g%3E%3C/svg%3E%0A");
  }

  .file {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='628.027' height='786.012' viewBox='0 0 628.027 786.012'%3E%3Cdefs%3E%3ClinearGradient id='linear-gradient' x1='0.5' x2='0.5' y2='1' gradientUnits='objectBoundingBox'%3E%3Cstop offset='0' stop-color='%238a97ac'/%3E%3Cstop offset='1' stop-color='%235d6c83'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Group_5' data-name='Group 5' transform='translate(-646 -92.988)'%3E%3Cpath id='Union_2' data-name='Union 2' d='M40,786A40,40,0,0,1,0,746V40A40,40,0,0,1,40,0H501V103h29v24h98V746a40,40,0,0,1-40,40Z' transform='translate(646 93)' fill='url(%23linear-gradient)'/%3E%3Cpath id='Intersection_2' data-name='Intersection 2' d='M.409,162.042l.058-109.9c31.605,29.739,125.37,125.377,125.37,125.377l-109.976.049A20.025,20.025,0,0,1,.409,162.042Z' transform='translate(1147 42)' fill='%23bec8d9' stroke='%23bec8d9' stroke-width='1'/%3E%3C/g%3E%3C/svg%3E%0A");

    span {
      color: #fff;
      font-size: 8px;
      text-decoration: none;
    }
  }

  .invisible td:not(.column-item-icon) {
    text-decoration: line-through;
  }

  .action {
    .show {
      visibility: visible;
      opacity: 1;
    }

    .dropdown-menu {
      margin-top: 10px;
      left: -100px;

      button {
        width: 100%;
      }
    }
  }
}

#tag-modal {
  .modal-dialog .modal-content .modal-body {
    .multiselect {
      ::v-deep .multiselect__content-wrapper ul li {
        span {
          white-space: pre-wrap;
        }
      }
    }
  }
}

#tag-filter {
  option {
    white-space: pre-wrap;
  }
}
</style>
